import { UtilService } from './../services/util.service';
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import * as S3 from "aws-sdk/clients/s3";
import * as _ from "lodash";
import ciudades from "src/assets/json/ciudades-colombia.json";


@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.scss"]
})
export class UsuariosComponent implements OnInit {
  /** Cargando para el exportar */
  exportando=false;
  /** Lista de ciudades */
  ciudades: any[] = ciudades;
  cargaCompleta = false;
  /** Referencia a los modal de crear y editar usuarios */
  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /** Referencia a formularios de datos para la creacion y edicion de usuarios */
  frmUsuario: FormGroup;
  frmUsuarioEdit: FormGroup;
  /** Banderas de control para saber si se esta intentando enviar algun formulario */
  submitted = false;
  submitted2 = false;
  /** Variables de control para las cargas de informacion muestra el loading */
  spinerEdit = false;
  spinerGuardar = false;
  /** Configuracion del las opcines de la tabla de usuarios */
  settings = {
    //hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' }
      ]
    },
    columns: {
      nombre: { title: "Nombre", filter: true },
      email: { title: "Email", filter: true},
      cargo: { title: "Cargo", filter: false },
      tipo: { title: "Tipo", filter: false },
      ubicacion: { title: "Ciudad", filter: true },
      foto: {
        title: "Foto",
        type: "html",
        filter: false,
        valuePrepareFunction: img => {
          return '<img src="' + img + '" class="foto-lista" />';
        }
      }
    }
  };
  /** Lista completa de usuarios */
  listaDeusuarios = [];
  /** Lista de usuarios con geo referencia */
  listaGeoreferencia= [];
  /** Lista de usuarios con filtro de geoReferencia */
  listaFiltrada= [];
  /** Modelo de datos para ubicacion a filtar */
  filtroUbicacion="";
  /** Centro del mapa de geoReferencias */
  centerLat: number;
  centerLon: number;
  /** Zoom del mapa */
  zoom=14;
  datos: any[];
  /** Datos de coneccion con el bucket de s3 */
  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1"
  });
  /** objetos contenedores de la informacion de las imagenes al momento de crear y editar usuario */
  contenidoFile: any;
  contenidoFileE: any;
  /** Objeto del formulario de edicion */
  datosEdit = {
    _id: "",
    nombre: "",
    email: "",
    celular: "",
    tipo: "",
    ferreteria: "",
    cargo: "",
    ubicacion: "",
    estado: "",
    foto: "",
    genero: "",
    ganadorPremio: ""
  };
  /** Source de datos para cargar la informacion de la lista de usuarios en la tabla ng2-smartable */
  public source: LocalDataSource;
  optFerretero= ['Dueño', 'Administrador', 'Vendedor'];
  optProveedor= ['Dueño', 'Marketing', 'Ventas'];
  /** Foto */
  previewE: any = null;
  inputFile: any;
  /** variable de busquede en el objeto de ciudades */
  keyword = 'ciudad';
  /** Modelo para la ubicacion del usuario */
  ubicacionC = '';
  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    public utilService: UtilService,
    private rutaActiva: ActivatedRoute
  ) {
    this.frmUsuario = this.fb.group({
      nombre: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      tipo: ["", [Validators.required]],
      celular: ["", []],
      ferreteria: ["", []],
      cargo: ["", []],
      estado: ["", []],
      genero: ["", []],
      password: ["",[Validators.required, Validators.minLength(6)]]
    });
    this.frmUsuarioEdit = this.fb.group({
      nombre: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      tipo: ["", [Validators.required]],
      celular: ["", []],
      ferreteria: ["", []],
      foto: ["", []],
      cargo: ["", []],
      estado: ["", []],
      genero: ["", []],
      _id: ["", []]
    });
  }

  ngOnInit() {
    this.getUsuarios();
  }
  /** Funcion para acortar llamado a valores de los formularios en el html */
  get f() {
    return this.frmUsuario.controls;
  }
  get f2() {
    return this.frmUsuarioEdit.controls;
  }
  /**
   * Obtiene lista de usuarios registrados en el sistema
   */
  getUsuarios() {
    this.mainService.get({ api: "api/user" }).subscribe(users => {
      for (let usu of users) {
        if (usu.foto === undefined || usu.foto === null || usu.foto === "") {
          usu.foto = "/assets/admin/sinfoto.png";
        }
        if (usu.geoReferencia !== null && usu.geoReferencia !== undefined) {
          this.listaGeoreferencia.push(usu);
        }
      }
      this.filtrarUbicacion();
      this.listaDeusuarios = users;
      this.source = new LocalDataSource(this.listaDeusuarios);
      this.cargaCompleta = true;
    });
  }

  /** Para modal **/
  crear() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.openEdit(this.dialogEdit, event.data);
        break;
      case "delete":
        //this.eliminarUsuario(event);
        break;
      case "detalle":
        //this.router.navigate(["dashboard/detalleusuario", event.data.uid]);
        break;
    }
  }
  /**
   * Metodo que permite cargar los datos en el modal en el cual se muestra el formulario de edicion
   * @param dialog Objeto dialog a desplegar para levantar la informacion a editar
   * @param data Objeto con la informacion del usuario seleccionado para editar
   */
  openEdit(dialog, data) {
    this.datosEdit.nombre = data.nombre;
    this.datosEdit.email = data.email;
    this.datosEdit.tipo = data.tipo;
    this.datosEdit.cargo = data.cargo;
    this.datosEdit.ferreteria = data.ferretetia;
    this.datosEdit._id = data._id;
    this.datosEdit.celular = data.celular;
    this.datosEdit.foto = "";
    if (data.foto !== undefined && data.foto !== null) {
      this.datosEdit.foto = data.foto;
    }
    if (data.ganadorPremio !== undefined && data.ganadorPremio !== null) {
      this.datosEdit.ganadorPremio = data.ganadorPremio;
    } else {
      this.datosEdit.ganadorPremio = "";
    }
    this.datosEdit.ferreteria = "";
    if (data.ferreteria !== undefined && data.ferreteria !== null) {
      this.datosEdit.ferreteria = data.ferreteria;
    }
    this.datosEdit.ubicacion = "";
    if (data.ubicacion !== undefined && data.ubicacion !== null) {
      this.datosEdit.ubicacion = data.ubicacion;
    }
    this.datosEdit.genero = "";
    if (data.genero !== undefined && data.genero !== null) {
      this.datosEdit.genero = data.genero;
    }
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /**
   * Ejecuta la funcion para guadar los datos del usuario a crear
   */
  guardarUsuario() {
    this.submitted = true;
    if (this.frmUsuario.invalid) {
      return false;
    }
    this.spinerGuardar = true;
    const usuarios = this.frmUsuario.value;
    usuarios.createdAt = new Date();
    usuarios.ubicacion = this.ubicacionC;
    //@ts-ignore
    if (this.ubicacionC.ciudad) {
      //@ts-ignore
      usuarios.ubicacion= this.ubicacionC.ciudad;
    }
    usuarios.foto = "/assets/admin/sinfoto.png";
    let self = this;
    this.mainService.post2("users/register", usuarios).subscribe(res => {
      if (res.success) {
        self.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se creo con éxito el usuario!"
        );
        self.frmUsuario.reset();
        self.submitted = false;
        this.ubicacionC = '';
        self.getUsuarios();
        const element: HTMLElement = document.getElementById(
          "btn-close"
        ) as HTMLElement;
        element.click();
      } else {
        self.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
      self.spinerGuardar = false;
    });
  }

  /** funciones para carga de icono edicion*/
  public abrirArchivoE () {
    const element: HTMLElement = document.getElementById(
      'img-segundariasE'
    ) as HTMLElement
    element.click();
  }
  /**
   * Metodo que permite obtener la informacion de la imagen a cargar y asignar una vista previa de la imagen al momento de editar
   * @param event Objeto de la imagen a editar
   */
  public inputImagenE (event) {
    this.contenidoFileE = event.target.files[0];
    let archivos = event.target.files
    for (let archivo of archivos) {
      let reader = new FileReader()
      reader.onload = (img: any) => {
        this.previewE = img.target.result;
      }
      reader.readAsDataURL(archivo)
    }
  }
  /**
   * Funcion para crear codigo aleatorio para las referenicas de las imagenes
   * @param length longintud del codigo aleataorio a crear
   */
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Metodo para guardar las imagenes tanto al editar como al crear
   * @param tipo determina si la imagen es para editar o crear la noticia
   * @param pos Posicion dentro del arreglo que almacena las imagenes tanto en el crear como en el editar
   */
  guardarImagen(tipo) {
    const usuario = this.frmUsuarioEdit.value;
    let idImagen = "usuario-"+ usuario._id;
    let imagen = this.contenidoFile;
    if (tipo === "editar") {
      imagen = this.contenidoFileE;
    }
    let self = this;
    if (imagen !== undefined) {
      let file = imagen;
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: idImagen,
        ContentType: file.type,
        Body: file
      };
      let options = {
        partSize: 10 * 1024 * 1024,
        queueSize: 1
      };
      let uploader = this.bucket.upload(params, options, function(err, data) {
        if (err) {
          console.log("PERDIDAS");
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          self.spinerEdit = false;
          self.spinerGuardar = false;
          return false;
        }
        
          self.datosEdit.foto = data.Location.toString();
          self.finalizarModificar();
        
      });
      uploader.on("httpUploadProgress", function(event) {
        console.log((event.loaded * 100) / event.total);
      });
    } else {
      self.finalizarModificar();
    }
  }
  /**
   * Funcion que ejecuta el servicio de modificar datos del usuario, primero verifica  si se cambio la imagen
   */
  modificarUsuario() {
    this.submitted2 = true;
    if (this.frmUsuarioEdit.invalid) {
      return false;
    }
    this.spinerEdit = true;
    this.guardarImagen('editar');
  }
  /**
   * Metodo que guarda la informacion en la base de datos luego de subir la imagen a S3 en caso de ser modificada
   */
  finalizarModificar() {
    const usuario = this.frmUsuarioEdit.value;
    usuario.foto = this.datosEdit.foto;
    usuario.ubicacion = this.datosEdit.ubicacion;
    usuario.ganadorPremio = false;
    if (this.datosEdit.ganadorPremio === 'true'){
      usuario.ganadorPremio = true;
    } 
    
    //@ts-ignore
    if (this.datosEdit.ubicacion.ciudad) {
      //@ts-ignore
      usuario.ubicacion= this.datosEdit.ubicacion.ciudad;
    }
    this.mainService.put2("api/user/"+usuario._id, usuario).subscribe(res => {
      console.log(res);
      if (res.success) {
        this.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se modifico con éxito el registro!"
        );
        this.frmUsuarioEdit.reset();
        this.submitted2 = false;
        this.reiniciarFormulario();
        this.getUsuarios();
        const element: HTMLElement = document.getElementById(
          "btn-close-edit"
        ) as HTMLElement;
        element.click();
      } else {
        this.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
      this.spinerEdit = false;
    });
  }
  /** reiniciar formulario de edicion */
  reiniciarFormulario() {
    this.frmUsuarioEdit.reset();
    this.submitted2 = false;
    this.datosEdit = {
      _id: "",
      nombre: "",
      email: "",
      celular: "",
      tipo: "",
      ferreteria: "",
      cargo: "",
      ubicacion: "",
      estado: "",
      foto: "",
      genero: "",
      ganadorPremio: ""
    };
    this.contenidoFileE = undefined;
    this.previewE = null;
    this.getUsuarios();
  }
  /**
   * Metodo para filtar ubicacion por ciudad, localidad o barrio marcado en el modelo
   */
  filtrarUbicacion() {
    if (this.filtroUbicacion !== '' ){
      this.listaFiltrada = [];
      for (let item of this.listaGeoreferencia) {
        let encontrado = item.geoReferencia.direccion.formatted_address.toLowerCase().indexOf(this.filtroUbicacion.toLowerCase());
        if (encontrado !== -1) {
          this.listaFiltrada.push(item);
        }
      }
    } else {
      this.listaFiltrada = this.listaGeoreferencia;
    }
    if (this.listaFiltrada.length > 0) {
      this.centerLat = this.listaFiltrada[0].geoReferencia.lat;
      this.centerLon = this.listaFiltrada[0].geoReferencia.lng;
    } else {
      this.centerLat = 0;
      this.centerLon = 0;
    }
  }
  /** 
   * Metodo que hace el llamado al servico de exportat a xls
   */
  exportarXls(){
    this.exportando = true;
    let datos = {
      listado: this.listaDeusuarios,
      primeraFila: ['Nombre', 'Email', 'Tipo', 'Celular', 'Ferreteria', 'Cargo', 'Ciudad'],
      campos: ['nombre', 'email', 'tipo', 'celular', 'ferreteria', 'cargo', 'ubicacion'],
      nombre: 'Lista-de-usuarios'
    };
    if (this.utilService.reporteXls(datos)) {
      this.exportando = false;
    }
  }
  /**
   * Dar el valor al modelo de edicion de ubicacion a
   * @param item Item seleccionado del objeto
   */
  selectEvent(item) {
    this.datosEdit.ubicacion = item.ciudad;
    console.log(this.datosEdit.ubicacion);
  }
  /**
   * Dar el valor al modelo de edicion de ubicacion a
   * @param item Item seleccionado del objeto
   */
  selectEventC(item) {
    this.ubicacionC = item.ciudad;
  }
 
}
