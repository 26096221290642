import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './public/services/auth.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'admin-nebular';

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private authService: AuthService
  ) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (isPlatformBrowser(this.platformId)) {
        this.validationJwt();
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
  }

  /**
   * Function to validate if JWT is expired
   */
  private validationJwt() {
    //in this case JWT is set in local storage as tkYFweb
    const currentToken = localStorage.getItem('tkYFadmin');
    /*if (currentToken) this.authService.validateJwtExpired(currentToken).subscribe((res: any) => {
      console.log(res)
      if (!res.success) {
        let { newToken, user } = res;
        this.authService.storeUserData(newToken, user)
      }
    });*/
  }
}
