import { UtilService } from "../services/util.service";
import { ExpositoresInterface } from "../../model/expositores";
import { MainService } from "../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import Swal from "sweetalert2";
type AOA = any[][];
import * as S3 from "aws-sdk/clients/s3";
@Component({
  selector: 'app-config-salon',
  templateUrl: './config-salon.component.html',
  styleUrls: ['./config-salon.component.scss']
})
export class ConfigSalonComponent implements OnInit {
  @ViewChild('fileUploader', { static: true}) public fileUploader: ElementRef;

  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1",
  });
  /** Bandera de control para la carga inicial */
  cargaCompleta = false;

  /** Variables de control de envio de informacion en los formularios */
  spinerEdit = false;

  spinerGuardar = false;
  /*** Configuracion de la informacion a mostrar en la tabla de Expositores **/
  settings = {
    //hideSubHeader: false,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nombre: { title: "Nombre", filter: true },
      stand: { title: "Stand", filter: true },
      telefono: { title: "Whatsapp", filter: true },
      email: { title: "Email", filter: true },
      logo: {
        title: "Logo",
        filter: false,
        type: "html",
        valuePrepareFunction: (img) => {
          if (img !== null && img !== undefined && img !== "") {
            return '<img src="' + img + '" class="foto-lista" />';
          }
          return '<img src="/assets/admin/sin-imagen.jpg" class="foto-lista" />';
        },
      },
    },
  };

  contenidoFileE: any;
  /** Cargando exportar */
  exportando = false;
  nombre: any;
  newPdf: any;
  previewE: any = null;
  mapaActual: any;
  idUpdate: any;
  nameArchivo: any;

  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    private rutaActiva: ActivatedRoute,
    public utilService: UtilService
  ) {
  }

  ngOnInit() {
    this.getConfig()
  }


  modificar(){
/**?
 * 
 *   nombre: { type: String, required: false },
    estado: { type: String, required: false, default: 'Inactivo' },
    imagen_principal: { type: String, required: false },
    mapa_pdf: { type: String, required: false },
    mapa_pdf_anterior: { type: String, required: false },
 */
    if(!this.nombre || this.nombre === ''){
      this.showToast(
        "top-right",
        "warning",
        "Érror!",
        "El nombre no puede ir vacío!"
      );
    } else {
      if (this.contenidoFileE){
        this.guardarImagen()
      } else {
        let update = {
          nombre: this.nombre,
          mapa_pdf: this.mapaActual,
        }
        this.ejecutaActualizar(update)
      }
    }
   // 
  }
   ejecutaActualizar(data: any){
    //gran_salon_activo_update
    this.spinerEdit= true;

    this.mainService
      .put2("api/gran_salon_activo_update/" + this.idUpdate, data)
      .subscribe((res) => {
        if (res.success) {
          this.showToast(
            "top-right",
            "success",
            "Éxito!",
            "Se modifico con éxito el registro!"
          );
          this.getConfig();
          this.deleteMap();
          this.spinerEdit = false;
        } else {
          this.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
        }
        this.spinerEdit = false;
      });



  }
  viewMapActual(){
    window.open(this.mapaActual, "_blank");
  }
  deleteMap(){
    this.contenidoFileE = undefined;
    this.nameArchivo = undefined
    this.previewE = undefined;
    this.fileUploader.nativeElement.value = '';
    //window.open(this.previewE, "_blank");
  }
  /**
   * Funcion para obtener listado de proveedores
   */
  getConfig() {
    this.mainService.get({ api: "api/gran_salon_activo" }).subscribe((data) => {
      this.idUpdate =  data[0]._id
      this.nombre = data[0].nombre
      this.mapaActual = data[0].mapa_pdf
      this.cargaCompleta = true;
    });
  }


  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

 
  /**
   * Funcion que inicia proceso de guardar expositor
   */
  guardar() {

    this.spinerGuardar = true;
  }


  /** funciones para carga de icono edicion*/
  public abrirArchivoE() {
    const element: HTMLElement = document.getElementById(
      "img-segundariasE"
    ) as HTMLElement;
    element.click();
  }
  public inputImagenE(event) {
    this.contenidoFileE = event.target.files[0];
    this.nameArchivo = this.contenidoFileE.name
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.previewE = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
  /**
   * Funcion para crear codigo aleatorio para las referenicas de las imagenes
   * @param length longintud del codigo aleataorio a crear
   */
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Metodo para guardar las imagenes tanto al editar como al crear
   * @param pos Posicion dentro del arreglo que almacena las imagenes tanto en el crear como en el editar
   */
  guardarImagen() {
    let idImagen = "pdf-" + this.makeid(8);
    let imagen
    imagen = this.contenidoFileE;
    let self = this;
    let file = imagen;
    let params = {
      Bucket: "yoferretero-recursos",
      ACL: "public-read",
      Key: idImagen,
      ContentType: file.type,
      Body: file,
    };
    let options = {
      partSize: 10 * 1024 * 1024,
      queueSize: 1,
    };
    let uploader = this.bucket.upload(params, options, function (err, data) {
      if (err) {
        self.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
        self.spinerEdit = false;
        self.spinerGuardar = false;
        return false;
      }
      let timestamp = new Date().getTime();
      self.newPdf= data.Location.toString() + "?t=" + timestamp;
      let update = {
        nombre: self.nombre,
        mapa_pdf: self.newPdf,
        mapa_pdf_anterior:  self.mapaActual,
      }
      self.ejecutaActualizar(update);

    });
    uploader.on("httpUploadProgress", function (event) {
      console.log("UPDATE");
    });
  }
}
