import { Injectable } from "@angular/core";
import * as S3 from "aws-sdk/clients/s3";
import { NbToastrService } from "@nebular/theme";
import * as XLSX from "xlsx";
type AOA = any[][];
import * as moment from "moment";
@Injectable({
  providedIn: "root"
})
export class UtilService {
  /** Credendiciales de S3 */
  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1"
  });
  constructor(private toastrService: NbToastrService) {}
  /**
   * Muestra mensaje de los parametros enviados
   * @param position Posicion donde aparece el mensaje
   * @param status Define el color del mensaje a mostrar
   * @param titulo Titulo del mensaje
   * @param mensaje Texto del mensaje
   */
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /**
   * Cargar imagen a S3
   * @param param0 Objeto de opciones para la carga de imagen en s3
   */
  public async cargarImagen({ idS3, file }) {
    return new Promise((success, err) => {
      let idBanner = idS3;
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: idBanner,
        ContentType: file.type,
        Body: file
      };
      let options = {
        partSize: 10 * 1024 * 1024,
        queueSize: 1
      };
      let self = this;
      let uploader = this.bucket.upload(params, options, function(err, data) {
        if (err) {
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          success({success: false});
          return false;
        }
        let timestamp = new Date().getTime();
        let image = data.Location.toString()+"?t=" + timestamp;
        success({success: true, url: image});
        return true;
      });
      uploader.on("httpUploadProgress", function(event) {
        console.log((event.loaded * 100) / event.total);
      });
    });
  }
  /**
   * Generar cadena aleatoria para id de imagenes
   * @param length tamaño de cadena aleatoria a generar
   */
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Genera reportes de excel de las tablas de datos segun los parametros enviados
   * @param param0 Parametros para generar reporte de excel para las distintas tablas
   */
  reporteXls({ listado, primeraFila, campos, nombre}) {
    let arreglo = [];
    arreglo.push(primeraFila);
    listado.forEach(element => {
      let array = [];
      for (let cmp of campos) {
        if ((typeof element[cmp] === "object" || typeof element[cmp] === 'function') && (element[cmp] !== null) ) {
          array.push(JSON.stringify(element[cmp]));
        } else {
          array.push(element[cmp]);
        }
      }
      arreglo.push(array);
    });
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(arreglo);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(
      wb,
      nombre + moment().format("YYYYMMDD") + ".xlsx"
    );
    return true;
  }
  /**
   * Generar url amigable para las noticias y ferreofertas
   * @param slug titulo del componente a generar slug
   */
  generateSlug(slug) {
    // Reemplaza los carácteres especiales | simbolos con un espacio 
    slug = slug.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
    // Corta los espacios al inicio y al final del sluging 
    slug = slug.replace(/^\s+|\s+$/gm, '');
    // Reemplaza el espacio con guión  
    slug = slug.replace(/\s+/g, '-');
    slug = this.quitarAcentos(slug);
    return slug;
  }
  /** 
   * Quitar acentos
   */
  quitarAcentos(cadena)
  {
	  let chars={
		"á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
		"à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
		"Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
		"À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
	  let expr=/[áàéèíìóòúùñ]/ig;
	  let res=cadena.replace(expr,function(e){return chars[e]});
	  return res;
  }
}
