import { UtilService } from "./../services/util.service";
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import * as _ from "lodash";

@Component({
  selector: "app-premio",
  templateUrl: "./premio.component.html",
  styleUrls: ["./premio.component.scss"]
})
export class PremioComponent implements OnInit {
  /** Variable de control */
  cargaCompleta = false;
  /*  identificador para el modal de edicion */
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /** Identificador de formulario de edicion */
  frm: FormGroup;
  /** Variables de control para el envio de datos del formulario */
  submitted2 = false;
  spinerEdit = false;
  /** Configuracion de datos a mostrar en el dataTable */
  settings = {
    hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [{ name: "edit", title: '<i class="nb-compose"></i> ' }]
    },
    columns: {
      titulo: { title: "Titulo" },
      texto: { title: "Texto" },
      logo: {
        title: "Logo",
        type: "html",
        valuePrepareFunction: img => {
          if (img !== null && img !== undefined && img !== "") {
            if (img.url !== "") {
              return '<img src="' + img.url + '" class="foto-lista" />';
            }
          }
          return '<img src="/assets/admin/sin-imagen.jpg" class="foto-lista" />';
        }
      },
      imagen: {
        title: "Imagen",
        type: "html",
        valuePrepareFunction: img => {
          if (img !== null && img !== undefined && img !== "") {
            if (img.url !== "") {
              return '<img src="' + img.url + '" class="foto-lista" />';
            }
          }
          return '<img src="/assets/admin/sin-imagen.jpg" class="foto-lista" />';
        }
      }
    }
  };
  /** Arreglo donde cargamos los datos del premio princiapl */
  lista = [];
  /** Variables para almacenar informacion de imagenes a cargar */
  contenidoFileE: any = undefined;
  contenidoFileE2: any = undefined;
  /** Identificadores para variables de vista previa de imagenes */
  preview: any = null;
  preview2: any = null;
  /** Modelo de datos para la edicion de informacion del premio del mes */
  datosEdit = {
    _id: "",
    titulo: "",
    texto: "",
    logo: { url: "", refS3: "" },
    imagen: { url: "", refS3: "" }
  };
  /** Source para cargar la informacion a mostrar en tabla de premio del mes */
  public source: LocalDataSource;
  constructor(
    public fb: FormBuilder,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    private rutaActiva: ActivatedRoute,
    public utilSevice: UtilService
  ) {
    this.frm = this.fb.group({
      titulo: ["", [Validators.required]],
      texto: ["", [Validators.required]],
      _id: ["", []]
    });
  }

  ngOnInit() {
    this.getPremio();
  }
  /**
   * Funcion para resumir metodo de obtener valores del formuario de edicion
   */
  get f2() {
    return this.frm.controls;
  }
  /**
   * Obtener premios registrados
   */
  getPremio() {
    this.mainService.get({ api: "api/premio" }).subscribe(data => {
      for (let d of data) {
        if (d.imagen === undefined || d.imagen === null || d.imagen === "") {
          d.imagen = { url: "/assets/admin/sinfoto.png", refS3: "" };
        }
        if (d.logo === undefined || d.logo === null || d.logo === "") {
          d.logo = { url: "/assets/admin/sinfoto.png", refS3: "" };
        }
      }
      this.lista = data;
      this.source = new LocalDataSource(this.lista);
      this.cargaCompleta = true;
    });
  }
  /**
   * Despliega el modal
   * @param dialog Identificador del modal a desplegar
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.openEdit(this.dialogEdit, event.data);
        break;
    }
  }
  /**
   * Asignar los datos del objeto seleccionado en el data-table al modelo de edicion datosEdit
   * @param dialog Referencia al modal del formulario de edicion
   * @param data Objeto banner seleccionada desde el data-table
   */
  openEdit(dialog, data) {
    this.datosEdit._id = data._id;
    this.datosEdit.titulo = data.titulo;
    this.datosEdit.texto = data.texto;
    this.datosEdit.imagen = data.imagen;
    this.datosEdit.logo = data.logo;
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /**
   * Metodo que inicia modificacion de los datos del premio del mes
   */
  public async modificar() {
    this.submitted2 = true;
    const premio = this.frm.value;
    if (this.frm.invalid) {
      return false;
    }
    this.spinerEdit = true;
    if (this.contenidoFileE !== undefined) {
      let file = this.contenidoFileE;
      let idPremio = "premioImg-" + premio._id;
      if (this.datosEdit.imagen.refS3 !== "") {
        idPremio = this.datosEdit.imagen.refS3;
      }
      let objCarga = await this.utilSevice.cargarImagen({
        idS3: idPremio,
        file: file
      });
      //@ts-ignore
      if (objCarga.success) {
        this.datosEdit.imagen = {
          //@ts-ignore
          url: objCarga.url,
          refS3: idPremio
        };
        if (this.contenidoFileE2 !== undefined) {
          this.cargarIcono();
        } else {
          this.ejecutarModificar();
        }
      } else {
        this.utilSevice.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
        this.spinerEdit = false;
        return false;
      }
    } else {
      if (this.contenidoFileE2 !== undefined) {
        this.cargarIcono();
      } else {
        this.ejecutarModificar();
      }
    }
  }
  /**
   * Funcion para modificar logo del premio y cargarlo a s3 en caso de ser seleccionado
   */
  public async cargarIcono() {
    let file = this.contenidoFileE2;
    let idPremio = "premioLogo-" + this.datosEdit._id;
    if (this.datosEdit.logo.refS3 !== "") {
      idPremio = this.datosEdit.logo.refS3;
    }
    let objCarga = await this.utilSevice.cargarImagen({
      idS3: idPremio,
      file: file
    });
    //@ts-ignore
    if (objCarga.success) {
      this.datosEdit.logo = {
        //@ts-ignore
        url: objCarga.url,
        refS3: idPremio
      };
      this.ejecutarModificar();
    } else {
      this.utilSevice.showToast(
        "top-right",
        "warning",
        "Érror!",
        "Ocurrio un error por favor intenta de nuevo!"
      );
      this.spinerEdit = false;
      return false;
    }
  }
  /**
   * Ejecuta los metodos que conectan con el servicio para almacenar los datos de premio
   */
  ejecutarModificar() {
    this.mainService
      .put2("api/premio/" + this.datosEdit._id, this.datosEdit)
      .subscribe(res => {
        if (res.success) {
          this.utilSevice.showToast(
            "top-right",
            "success",
            "Éxito!",
            "Se modifico con éxito el registro!"
          );
          this.reiniciarFormulario();
          const element: HTMLElement = document.getElementById(
            "btn-close-edit"
          ) as HTMLElement;
          element.click();
        } else {
          this.utilSevice.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
        }
        this.spinerEdit = false;
      });
  }
  /**
   * Funcion para reiniciar los datos del formulario para la proxima edicion
   */
  reiniciarFormulario() {
    this.frm.reset();
    this.submitted2 = false;
    this.datosEdit = {
      _id: "",
      titulo: "",
      texto: "",
      imagen: { url: "", refS3: "" },
      logo: { url: "", refS3: "" }
    };
    this.getPremio();
    this.contenidoFileE = undefined;
    this.contenidoFileE2 = undefined;
    this.preview = null;
    this.preview2 = null;
  }

  /** funciones para carga de imagen */
  public abrirArchivo() {
    const element: HTMLElement = document.getElementById(
      "imagen"
    ) as HTMLElement;
    element.click();
  }
  public inputImagen(event) {
    this.contenidoFileE = event.target.files[0];
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.preview = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
  /** funciones para carga de logo edicion*/
  public abrirArchivoE() {
    const element: HTMLElement = document.getElementById(
      "logo"
    ) as HTMLElement;
    element.click();
  }
  public inputImagenE(event) {
    this.contenidoFileE2 = event.target.files[0];
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.preview2 = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
}
