import { UtilService } from './../services/util.service';
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";

import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import * as S3 from "aws-sdk/clients/s3";
import * as _ from "lodash";
import Swal from "sweetalert2";
import { NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { AngularEditorConfig } from "@kolkov/angular-editor";
/**
 * Modulo para crear editar y eliminar ofertas
 */
@Component({
  selector: "app-ofertas",
  templateUrl: "./ofertas.component.html",
  styleUrls: ["./ofertas.component.scss"]
})
export class OfertasComponent implements OnInit {
  /** Lista de ciudades */
  ciudades: string[] = [
    "Bogotá",
    "Medellín",
    "Cali",
    "Barranquilla",
    "Cartagena de Indias",
    "Soacha",
    "	Cúcuta",
    "Soledad",
    "Bucaramanga",
    "Bello",
    "Villavicencio",
    "Ibagué",
    "Santa Marta",
    "Valledupar",
    "Manizales",
    "Pereira",
    "Montería",
    "	Neiva",
    "Pasto",
    "Armenia"
  ];

  /** Variable de control para loading inicial de carga de informacion */
  cargaCompleta = false;
  /*  Para el modal */
  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /** Formulario para crear oferta */
  frmOferta: FormGroup;
  frmOfertaEdit: FormGroup;
  /** Variales de control determinar si se esta enviando la informacion */
  submitted = false;
  submitted2 = false;
  /** Espiner para metodos de crear y modificar oferta */
  spinerEdit = false;
  spinerGuardar = false;
  /*****/
  settings = {
    hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' }
      ]
    },
    columns: {
      nombre: { title: "Nombre" },
      proveedor: { title: "Proveedor" },
      email: { title: "Email" },
      /*detalle: {
        title: "Detalle",
        type: "html",
        valuePrepareFunction: cont => {
          return cont;
        }
      },
      condiciones: {
        title: "Condiciones",
        type: "html",
        valuePrepareFunction: cont => {
          return cont;
        }
      },*/
      caducidad: {
        title: "Fecha de caducidad",
        type: "date",
        valuePrepareFunction: date => {
          return new Date(date);
        }
      },
      descuento: { title: "Descuento" },
      ciudad: { title: "Ciudad" },
      referencia: { title: "Referencia" }
    }
  };
  /** Arreglo de las ofertas registradas */
  listaDeOfertas = [];

  /** Credenciales para la carga de imagenes en amazong */
  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1"
  });
  /** Variables de control para las imagenes */
  contenidoFile: any[] = [undefined, undefined, undefined];
  contenidoFileE: any[] = [undefined, undefined, undefined];
  /** Carga de informacion a editar */
  datosEdit = {
    _id: "",
    nombre: "",
    proveedor: "",
    descuento: 0,
    email: "",
    caducidad: new Date(),
    imagenes: [],
    ciudad: "",
    referencia: "",
    detalle: "",
    condiciones: ""
  };
  /** para modelo del calendario */
  date: NgbDateStruct = { year: 1789, month: 7, day: 14 };
  /** Origen de datos para tabla */
  public source: LocalDataSource;
  /** Variable de control para mostrar modal de edicion */
  muestraEdit = false;
  /** Arreglo de imagenes cuando se crea oferta */
  imagenes = [];
  /** arreglo selector con imagen cargada */
  contentImagenes: any[] = [];
  /** arreglo de vistas previas al crear oferta */
  previewImagenes: any[] = [];
  /** arreglo selector con imagen cargada al editar oferta */
  contentImagenesE: any[] = [];
  /** arreglo vistaPrevia en edicion*/
  previewImagenesE: any[] = [];
  /** Configuracion del editor de contenidos */
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Escribe aquí...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" }
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ],
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      [],
      ["fontSize", "insertImage", "insertVideo", "removeFormat"]
    ]
  };
  /** Cargando del exportar */
  exportando = false;
  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    public utilService: UtilService,
    private rutaActiva: ActivatedRoute
  ) {
    this.frmOferta = this.fb.group({
      nombre: ["", [Validators.required]],
      proveedor: ["", [Validators.required]],
      detalle: ["", [Validators.required]],
      condiciones: ["", [Validators.required]],
      ciudad: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      caducidad: ["", []],
      descuento: ["", []],
      referencia: ["", []]
    });
    this.frmOfertaEdit = this.fb.group({
      nombre: ["", [Validators.required]],
      proveedor: ["", [Validators.required]],
      detalle: ["", [Validators.required]],
      condiciones: ["", [Validators.required]],
      ciudad: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      caducidad: ["", []],
      descuento: ["", []],
      referencia: ["", []]
    });
  }

  ngOnInit() {
    this.getOfertas();
  }
  /** Funcion para acortar llamado a valores de los formularios en el html */
  get f() {
    return this.frmOferta.controls;
  }

  get f2() {
    return this.frmOfertaEdit.controls;
  }
  /**
   * Obtener todas las ofertas registradas
   */
  getOfertas() {
    this.mainService.get({ api: "api/ofertas" }).subscribe(ofertas => {
      this.listaDeOfertas = ofertas;
      this.source = new LocalDataSource(this.listaDeOfertas);
      this.cargaCompleta = true;
    });
  }
  /** Para modal **/

  crear() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.muestraEdit = false;
        this.openEdit(this.dialogEdit, event.data);
        break;
      case "delete":
        this.eliminarOferta(event);
        break;
      case "detalle":
        //this.router.navigate(["dashboard/detalleusuario", event.data.uid]);
        break;
    }
  }
  /**
   * Eliminar oferta
   * @param even Datos de oferta a eliminar
   */
  eliminarOferta(even) {
    Swal.fire({
      title: "¿Seguro que desea eliminar la oferta?",
      text: "Los datos borrados no podrar ser recuperados!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No eliminar!"
    }).then(result => {
      if (result.value) {
        this.cargaCompleta = false;
        this.mainService
          .delete2("api/ofertas/" + even.data._id)
          .subscribe(res => {
            this.getOfertas();
            //this.cargaCompleta = true;
            if (res) {
              Swal.fire(
                "Se elimino con exito!",
                "La oferta fue eliminada.",
                "success"
              );
            }
          });
      }
    });
  }
  /**
   * Funcion que carga los datos a editar y abre el modal de edicion
   */
  openEdit(dialog, data) {
    this.datosEdit.nombre = data.nombre;
    this.datosEdit.proveedor = data.proveedor;
    this.datosEdit.imagenes = [];
    if (data.imagenes !== null && data.imagenes !== undefined) {
      this.datosEdit.imagenes = data.imagenes;
    }
    this.datosEdit.ciudad = data.ciudad;
    this.datosEdit.descuento = data.descuento;
    this.datosEdit.referencia = data.referencia;
    this.datosEdit._id = data._id;
    this.datosEdit.email = data.email;
    let fecha = new Date(data.caducidad);
    this.datosEdit.caducidad = fecha;
    this.datosEdit.detalle = data.detalle;
    this.datosEdit.condiciones = data.condiciones;
    this.muestraEdit = true;
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }

  /** funciones para abrir explorardor de archivo para editar imagen de icono edicion*/
  public abrirArchivoE() {
    const element: HTMLElement = document.getElementById(
      "input-imagenE"
    ) as HTMLElement;
    element.click();
  }
  /** funciones para abrir explorardor de archivo para crear oferta de icono edicion*/
  public abrirArchivo() {
    const element: HTMLElement = document.getElementById(
      "input-imagen"
    ) as HTMLElement;
    element.click();
  }
  /**
   * Funcion para asignar la imagen a editar
   * @param event1 imagen nueva
   * @param img imagen a editar
   */
  public selectFileE(event1) {
    this.contentImagenesE.push(event1.target.files[0]);
    let archivos = event1.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.previewImagenesE.push(img.target.result);
      };
      reader.readAsDataURL(archivo);
    }
  }
  /**
   * Funcion para asignar la imagen a editar
   * @param event1 imagen nueva
   * @param img imagen a editar
   */
  public selectFile(event1) {
    this.contentImagenes.push(event1.target.files[0]);
    let archivos = event1.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.previewImagenes.push(img.target.result);
      };
      reader.readAsDataURL(archivo);
    }
  }
  /** Eliminar de lista de imagenes en editar oferta */
  deletePreviewImageE(pos) {
    this.contentImagenesE.splice(pos, 1);
    this.previewImagenesE.splice(pos, 1);
  }
  /** Eliminar de lista de imagenes en crear oferta */
  deletePreviewImage(pos) {
    this.contentImagenes.splice(pos, 1);
    this.previewImagenes.splice(pos, 1);
  }
  /** Eliminar de lista de imagenes guardadas en la oferta  */
  deleteImageEdit(pos) {
    this.datosEdit.imagenes.splice(pos, 1);
  }
  /**
   * Funcion para crear codigo aleatorio para las referenicas de las imagenes
   * @param length longintud del codigo aleataorio a crear
   */
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Metodo para validar informacion a guardar
   */
  guardarOferta() {
    this.submitted = true;
    if (this.frmOferta.invalid) {
      return false;
    }
    this.spinerGuardar = true;
    this.guardarImagen("crear", 0);
  }
  /**
   * Metodo para guardar las imagenes tanto al editar como al crear
   * @param tipo determina si la imagen es para editar o crear la noticia
   * @param pos Posicion dentro del arreglo que almacena las imagenes tanto en el crear como en el editar
   */
  guardarImagen(tipo, pos) {
    let idImagen = "oferta-" + pos + "-" + this.makeid(8);
    let imagenes = this.contentImagenes;
    if (tipo === "editar") {
      imagenes = this.contentImagenesE;
    }
    let self = this;
    if (imagenes[pos] !== undefined) {
      let file = imagenes[pos];
      let ext = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: idImagen + "." + ext,
        ContentType: file.type,
        Body: file
      };
      let options = {
        partSize: 10 * 1024 * 1024,
        queueSize: 1
      };
      let uploader = this.bucket.upload(params, options, function (err, data) {
        if (err) {
          console.log("PERDIDAS");
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          return false;
        }
        console.log("SUCCESS");
        let timestamp = new Date().getTime();
        if (tipo === "crear") {
          self.imagenes[pos] = {
            url: data.Location.toString() + "?t=" + timestamp,
            refS3: idImagen
          };
          if (pos === self.contentImagenes.length - 1) {
            self.finalizarCrear();
          } else {
            self.guardarImagen(tipo, pos + 1);
          }
        } else {
          self.datosEdit.imagenes.push({
            url: data.Location.toString() + "?t=" + timestamp,
            refS3: idImagen
          });
          if (pos === self.contentImagenesE.length - 1) {
            self.finalizarModificar();
          } else {
            self.guardarImagen(tipo, pos + 1);
          }
        }
      });
      uploader.on("httpUploadProgress", function (event) {
        console.log((event.loaded * 100) / event.total);
      });
    } else {
      if (tipo === "crear") {
        self.finalizarCrear();
      } else {
        self.finalizarModificar();
      }
    }
  }
  /**
   * Hace el llamado al servicio de crear la oferta una vez cargadas las imagenes
   */
  finalizarCrear() {
    const oferta = this.frmOferta.value;
    oferta.createdAt = new Date();
    let caducidad = new Date(oferta.caducidad);
    oferta.caducidad = caducidad;
    oferta.imagenes = this.imagenes;
    oferta.slug = this.utilService.generateSlug(oferta.nombre);
    let encontrado = false;
    for (let not of this.listaDeOfertas) {

      if (not.slug !== undefined && not.slug !== null) {
        if (not.slug === oferta.slug) {
          encontrado = true;
        }
      }

    }
    if (encontrado) {
      oferta.slug = oferta.slug + '-' + this.makeid(6);
    }
    console.log(oferta);
    this.mainService.post2("api/ofertas", oferta).subscribe(res => {
      if (res.success) {
        this.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se creo la oferta con éxito!"
        );
        this.frmOferta.reset();
        this.submitted = false;
        this.contenidoFile = [undefined, undefined, undefined];
        this.getOfertas();
        const element: HTMLElement = document.getElementById(
          "btn-close"
        ) as HTMLElement;
        element.click();
      } else {
        this.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
      this.spinerGuardar = false;
    });
  }
  /**
   * Modificar datos de la oferta
   */
  modificarOferta() {
    this.submitted2 = true;
    if (this.frmOfertaEdit.invalid) {
      return false;
    }
    this.spinerEdit = true;
    this.guardarImagen("editar", 0);
  }
  /**
   * Hace el llamado al metodo de modificar oferta una vez verificado si se hace cambio de imagen
   */
  finalizarModificar() {
    const oferta = this.frmOfertaEdit.value;
    oferta._id = this.datosEdit._id;
    let caducidad = new Date(this.datosEdit.caducidad);
    oferta.caducidad = caducidad;
    oferta.imagenes = this.datosEdit.imagenes;
    oferta.slug = this.utilService.generateSlug(oferta.nombre);
    let encontrado = false;
    for (let not of this.listaDeOfertas) {
      if (not._id !== oferta._id) {
        if (not.slug !== undefined && not.slug !== null) {
          if (not.slug === oferta.slug) {
            encontrado = true;
          }
        }
      }
    }
    if (encontrado) {
      oferta.slug = oferta.slug + '-' + this.makeid(6);
    }
    console.log(oferta);
    this.mainService
      .put2("api/ofertas/" + oferta._id, oferta)
      .subscribe(res => {
        if (res.success) {
          this.showToast(
            "top-right",
            "success",
            "Éxito!",
            "Se modifico la oferta con éxito!"
          );
          this.frmOfertaEdit.reset();
          this.submitted2 = false;
          this.datosEdit = {
            _id: "",
            nombre: "",
            proveedor: "",
            descuento: 0,
            email: "",
            caducidad: new Date(),
            imagenes: [],
            ciudad: "",
            referencia: "",
            detalle: "",
            condiciones: ""
          };
          this.getOfertas();
          this.contenidoFileE = [undefined, undefined, undefined];
          this.contentImagenesE = [];
          this.previewImagenesE = [];
          const element: HTMLElement = document.getElementById(
            "btn-close-edit"
          ) as HTMLElement;
          element.click();
        } else {
          this.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
        }
        this.spinerEdit = false;
      });
  }
  /** 
   * Metodo que hace el llamado al servico de exportat a xls
   */
  exportarXls() {
    this.exportando = true;
    let datos = {
      listado: this.listaDeOfertas,
      primeraFila: ['Nombre', 'Proveedor', 'Detalle', 'Condiciones', 'Ciudad', 'Email', 'Caducidad', 'Descuento', 'Referencia'],
      campos: ['nombre', 'proveedor', 'detalle', 'condiciones', 'ciudad', 'email', 'caducidad', 'descuento', 'referencia'],
      nombre: 'Lista-de-ofertas'
    };
    if (this.utilService.reporteXls(datos)) {
      this.exportando = false;
    }
  }
}
