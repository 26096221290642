import { GeorefenciaComponent } from "./public/georefencia/georefencia.component";
import { PremioComponent } from "./public/premio/premio.component";
import { PreguntasComponent } from "./public/preguntas/preguntas.component";
import { ProveedoresComponent } from "./public/proveedores/proveedores.component";
import { BannerComponent } from "./public/banner/banner.component";
import { CategoriasComponent } from "./public/categorias/categorias.component";
import { OfertasComponent } from "./public/ofertas/ofertas.component";
import { HoroscopoComponent } from "./public/horoscopo/horoscopo.component";
import { NoticiasComponent } from "./public/noticias/noticias.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./public/login/login.component";
import { DashboardComponent } from "./public/dashboard/dashboard.component";
import { HomeComponent } from "./public/home/home.component";
import { UsuariosComponent } from "./public/usuarios/usuarios.component";
import { TransaccionesComponent } from "./public/transacciones/transacciones.component";
import { YoaprendoComponent } from "./public/yoaprendo/yoaprendo.component";
import { GranSalonFerreteroComponent } from "./public/gran-salon-ferretero/gran-salon-ferretero.component";
import { ConfigSalonComponent } from './public/config-salon/config-salon.component';

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "registrar",
    component: LoginComponent,
  },
  {
    path: "panel",
    component: DashboardComponent,
    children: [
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "config-gransalon",
        component: ConfigSalonComponent,
      },
      {
        path: "usuarios",
        component: UsuariosComponent,
      },
      {
        path: "noticias",
        component: NoticiasComponent,
      },
      {
        path: "yoaprendo",
        component: YoaprendoComponent,
      },
      {
        path: "horoscopo",
        component: HoroscopoComponent,
      },
      {
        path: "ofertas",
        component: OfertasComponent,
      },
      {
        path: "categoria",
        component: CategoriasComponent,
      },
      {
        path: "banner",
        component: BannerComponent,
      },
      {
        path: "proveedores",
        component: ProveedoresComponent,
      },
      {
        path: "gransalon",
        component: GranSalonFerreteroComponent,
      },
      {
        path: "juego",
        component: PreguntasComponent,
      },
      {
        path: "premio",
        component: PremioComponent,
      },
      {
        path: "georeferencia",
        component: GeorefenciaComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
