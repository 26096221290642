import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { LocalDataSource } from "ng2-smart-table";
import { YoaprendoInterface } from "src/app/model/yoaprendo";
import { MainService } from "../services/main.service";
import { UtilService } from "../services/util.service";

@Component({
  selector: "app-yoaprendo",
  templateUrl: "./yoaprendo.component.html",
  styleUrls: ["./yoaprendo.component.scss"],
})
export class YoaprendoComponent implements OnInit {
  /** Cargando para el exportar */
  exportando = false;
  horaEntrada = { hour: 16, minute: 30 };
  horaEntradaEdit = { hour: 16, minute: 30 };
  cargaCompleta = false;
  /*  Para el modal de crear*/
  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  /*  Para el modal de editar*/
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /*  Formulario de crear*/
  frmVideo: FormGroup;
  /*  Formulario de edicion*/
  frmVideoEdit: FormGroup;
  /* Verifica si se esta enviando el formulario de creacion*/
  submitted = false;
  /* Verifica si se esta enviando el formulario de edicion*/
  submitted2 = false;
  /* Controla cargando al momento de crear noticia*/
  spinerEdit = false;
  /* Controla cargando al momento de editar noticia*/
  spinerGuardar = false;
  /*****/
  settings = {
    //hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      titulo: {
        title: "Titulo",
        type: "html",
        filter: true,
        valuePrepareFunction: (data) => {
          return data;
        },
      },
      autor: { title: "Autor", filter: true },
      visitas: {
        title: "Visitas",
        type: "html",
        filter: false
      },
      fechaPublicacion: {
        title: "Fecha de publicación",
        type: "date",
        filter: false,
        valuePrepareFunction: (date) => {
          return new Date(date);
        },
      },
    },
  };
  /** Lista de noticias a mostar en el dataTable */
  listaDeVideos = [];
  datos: any[];
  /** Configuracion de parametros del bucket en amazon */
  /*bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1"
  });*/
  /** Objeto donde se almacenar las imagenes del video cuando se crea y edita */
  //contenidoFile: any = undefined;
  //contenidoFileE: any = undefined;
  /** Imagen del icono */
  /*imagen: any = null;
  refS3: string = "";
  preview: any = null;
  previewE: any = null;*/
  /** Objeto de datos de noticia */
  datosEdit: YoaprendoInterface = {
    _id: "",
    titulo: "",
    autor: "",
    estado: "",
    fechaPublicacion: new Date(),
    imagen: "",
    video: "",
    refS3: "",
  };
  date: NgbDateStruct = { year: 1789, month: 7, day: 14 };
  public source: LocalDataSource;
  muestraEdit = false;

  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    public utilService: UtilService,
    private rutaActiva: ActivatedRoute
  ) {
    this.frmVideo = this.fb.group({
      titulo: ["", [Validators.required]],
      autor: ["", [Validators.required]],
      fechaPublicacion: ["", []],
      estado: ["", []],
      video: ["", []],
    });
    this.frmVideoEdit = this.fb.group({
      titulo: ["", [Validators.required]],
      autor: ["", [Validators.required]],
      estado: ["", []],
      video: ["", []],
      _id: ["", []],
    });
  }

  ngOnInit() {
    this.getVideos();
  }
  /** Funcion para acortar llamado a valores de los formulario de creacion en el html */
  get f() {
    return this.frmVideo.controls;
  }
  /** Funcion para acortar llamado a valores de los formulario de edicion en el html */
  get f2() {
    return this.frmVideoEdit.controls;
  }
  /**
   * Obtiene lista de videos
   */
  getVideos() {
    this.mainService.get({ api: "api/yoaprendo" }).subscribe((videos) => {
      this.listaDeVideos = videos;
      this.source = new LocalDataSource(this.listaDeVideos);
      this.cargaCompleta = true;
    });
  }
  /** Para modal **/

  crear() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.muestraEdit = false;
        this.openEdit(this.dialogEdit, event.data);
        break;
      case "delete":
        //this.eliminarUsuario(event);
        break;
      case "detalle":
        //this.router.navigate(["dashboard/detalleusuario", event.data.uid]);
        break;
    }
  }
  openEdit(dialog, data) {
    this.datosEdit.titulo = data.titulo;
    this.datosEdit.autor = data.autor;
    this.datosEdit.fechaPublicacion = data.fechaPublicacion;

    this.datosEdit.video = data.video;
    this.datosEdit.estado = data.estado;
    this.datosEdit._id = data._id;
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });

    let fecha = new Date(data.fechaPublicacion);
    this.datosEdit.fechaPublicacion = fecha;
    this.date.year = fecha.getFullYear();
    this.date.month = fecha.getMonth() + 1;
    this.date.day = fecha.getDate();
    this.horaEntradaEdit.hour = fecha.getHours();
    this.horaEntradaEdit.minute = fecha.getMinutes();
    this.muestraEdit = true;
  }

  guardarVideo() {
    this.submitted = true;
    if (this.frmVideo.invalid) {
      return false;
    }
    this.spinerGuardar = true;

    const video = this.frmVideo.value;
    video.createdAt = new Date();
    let fechaPubli = new Date(video.fechaPublicacion);
    fechaPubli.setHours(this.horaEntrada.hour, this.horaEntrada.minute);

    video.fechaPublicacion = fechaPubli;

    let self = this;
    video.comentarios = [];

    video.slug = self.utilService.generateSlug(video.titulo);
    let encontrado = false;
    for (let not of self.listaDeVideos) {
      if (not.slug !== undefined && not.slug !== null) {
        if (not.slug === video.slug) {
          encontrado = true;
        }
      }
    }
    if (encontrado) {
      video.slug = video.slug + "-" + self.makeid(6);
    }
    self.mainService.post2("api/yoaprendo", video).subscribe((res) => {
      if (res.success) {
        self.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se agreo video con éxito!"
        );
        self.frmVideo.reset();
        self.submitted = false;

        self.getVideos();
        const element: HTMLElement = document.getElementById(
          "btn-close"
        ) as HTMLElement;
        element.click();
      } else {
        self.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
      self.spinerGuardar = false;
    });
  }

  modificarVideo() {
    this.submitted2 = true;
    if (this.frmVideoEdit.invalid) {
      return false;
    }
    this.spinerEdit = true;
    const video = this.frmVideoEdit.value;
    video._id = this.datosEdit._id;
    let fechaPubli = new Date(this.datosEdit.fechaPublicacion);
    fechaPubli.setHours(this.horaEntradaEdit.hour, this.horaEntradaEdit.minute);

    video.fechaPublicacion = fechaPubli;

    let self = this;

    video.slug = this.utilService.generateSlug(video.titulo);
    let encontrado = false;
    for (let not of this.listaDeVideos) {
      if (not._id !== video._id) {
        if (not.slug !== undefined && not.slug !== null) {
          if (not.slug === video.slug) {
            encontrado = true;
          }
        }
      }
    }
    if (encontrado) {
      video.slug = video.slug + "-" + this.makeid(6);
    }
    this.mainService
      .put2("api/yoaprendo/" + video._id, video)
      .subscribe((res) => {
        if (res.success) {
          self.showToast(
            "top-right",
            "success",
            "Éxito!",
            "Se modifico con éxito el registro!"
          );
          self.frmVideoEdit.reset();
          self.submitted2 = false;
          self.datosEdit = {
            _id: "",
            titulo: "",
            autor: "",
            estado: "",
            fechaPublicacion: new Date(),
            video: "",
          };
          self.getVideos();
          const element: HTMLElement = document.getElementById(
            "btn-close-edit"
          ) as HTMLElement;
          element.click();
        } else {
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
        }
        self.spinerEdit = false;
      });
  }

  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Metodo que hace el llamado al servico de exportat a xls
   */
  exportarXls() {
    this.exportando = true;
    let datos = {
      listado: this.listaDeVideos,
      primeraFila: [
        "Titulo",
        "Autor",
        "Estado",
        "Fecha de publicación",
      ],
      campos: ["titulo", "autor", "estado", "fechaPublicacion"],
      nombre: "Lista-de-videos",
    };
    if (this.utilService.reporteXls(datos)) {
      this.exportando = false;
    }
  }

  // NOT USEFUL BUT FOR PROGRAMMING REASONS (MASSIVE UPDATE)
  // updateYoAprendoVisitas(){
  //   this.mainService.get2("api/visitaYoAprendoActualizar").subscribe(visitas => {
  //     console.log(visitas);
  //   });
  // }
}
