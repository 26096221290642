import { UtilService } from './../services/util.service';
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";

import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";

import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import * as moment from "moment";
import * as _ from "lodash";
import * as XLSX from "xlsx";
import { NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { NoticiasInterface } from "src/app/model/noticias";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-noticias",
  templateUrl: "./noticias.component.html",
  styleUrls: ["./noticias.component.scss"]
})
export class NoticiasComponent implements OnInit {
  /** Cargando para el exportar */
  exportando=false;
  horaEntrada = { hour: 16, minute: 30 };
  horaEntradaEdit = { hour: 16, minute: 30 };
  cargaCompleta = false;
  /*  Para el modal de crear*/
  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  /*  Para el modal de editar*/
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /*  Formulario de crear*/
  frmNoticia: FormGroup;
  /*  Formulario de edicion*/
  frmNoticiaEdit: FormGroup;
  /* Verifica si se esta enviando el formulario de creacion*/
  submitted = false;
  /* Verifica si se esta enviando el formulario de edicion*/
  submitted2 = false;
  /* Controla cargando al momento de crear noticia*/
  spinerEdit = false;
  /* Controla cargando al momento de editar noticia*/
  spinerGuardar = false;
  /*****/
  settings = {
    //hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' }
      ]
    },
    columns: {
      titulo: {
        title: "Titulo",
        type: "html",
        filter: true,
        valuePrepareFunction: data => {
          return data;
        }
      },
      autor: { title: "Autor", filter: true },
      contenido: {
        title: "Contenido",
        type: "html",
        filter: false,
        valuePrepareFunction: cont => {
          return cont.substring(0, 50) + "...";
        }
      },
      visitas: {
        title: "Visitas",
        type: "html",
        filter: false
      },
      fechaPublicacion: {
        title: "Fecha de publicación",
        type: "date",
        filter: false,
        valuePrepareFunction: date => {
          return new Date(date);
        }
      },
      imagen: {
        title: "Imagen",
        type: "html",
        filter: false,
        valuePrepareFunction: img => {
          return '<img src="' + img + '" class="foto-lista" />';
        }
      }
    }
  };
  /** Lista de noticias a mostar en el dataTable */
  listaDeNoticias = [];
  datos: any[];
  /** Configuracion de parametros del bucket en amazon */
  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1"
  });
  /** Objeto donde se almacenar las imagenes a de la noticia cuando se crea y edita */
  contenidoFile: any = undefined;
  contenidoFileE: any = undefined;
  /** Imagen del icono */
  imagen: any = null;
  refS3: string = "";
  preview: any = null;
  previewE: any = null;
  /** Objeto de datos de noticia */
  datosEdit: NoticiasInterface = {
    _id: "",
    titulo: "",
    autor: "",
    contenido: "",
    estado: "",
    fechaPublicacion: new Date(),
    imagen: "",
    video: "",
    refS3: ""
  };
  date: NgbDateStruct = { year: 1789, month: 7, day: 14 };
  public source: LocalDataSource;
  muestraEdit = false;
  /** Configuracion del editor de contenidos */
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "no",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Escribe aquí...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" }
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ],
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [[], ["fontSize","insertImage","insertVideo", "removeFormat"]]
  };
  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    public utilService: UtilService,
    private rutaActiva: ActivatedRoute
  ) {
    this.frmNoticia = this.fb.group({
      titulo: ["", [Validators.required]],
      autor: ["", [Validators.required]],
      contenido: ["", [Validators.required]],
      fechaPublicacion: ["", []],
      estado: ["", []],
      imagen: ["", []],
      video: ["", []]
    });
    this.frmNoticiaEdit = this.fb.group({
      titulo: ["", [Validators.required]],
      autor: ["", [Validators.required]],
      contenido: ["", [Validators.required]],
      estado: ["", []],
      imagen: ["", []],
      video: ["", []],
      _id: ["", []]
    });
  }

  ngOnInit() {
    this.getNoticias();
  }
  /** Funcion para acortar llamado a valores de los formulario de creacion en el html */
  get f() {
    return this.frmNoticia.controls;
  }
  /** Funcion para acortar llamado a valores de los formulario de edicion en el html */
  get f2() {
    return this.frmNoticiaEdit.controls;
  }
  /**
   * Obtiene lista de noticias
   */
  getNoticias() {
    this.mainService.get({ api: "api/noticia" }).subscribe(noticias => {
      this.listaDeNoticias = noticias;
      this.source = new LocalDataSource(this.listaDeNoticias);
      this.cargaCompleta = true;
    });
  }
  /** Para modal **/

  crear() {
    this.openDialogSave(this.dialog);
  }
  
  // NOT USEFUL FOR THE ADMINS, ONLY FOR PROGRAMMING REASONS
  // updateNoticiasVisitas(){
  //   this.mainService.get2("api/visitaActualizar").subscribe(visitas => {
  //     console.log(visitas);
  //   });
  // }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.muestraEdit = false;
        this.openEdit(this.dialogEdit, event.data);
        break;
      case "delete":
        //this.eliminarUsuario(event);
        break;
      case "detalle":
        //this.router.navigate(["dashboard/detalleusuario", event.data.uid]);
        break;
    }
  }
  openEdit(dialog, data) {
    this.datosEdit.titulo = data.titulo;
    this.datosEdit.autor = data.autor;
    this.datosEdit.fechaPublicacion = data.fechaPublicacion;
    this.datosEdit.imagen = "";
    if (data.imagen !== null && data.imagen !== undefined) {
      this.datosEdit.imagen = data.imagen;
    }
    this.datosEdit.video = data.video;
    this.datosEdit.estado = data.estado;
    this.datosEdit._id = data._id;
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
    this.datosEdit.refS3 = "noticia-" + this.makeid(8);
    if (data.refS3 !== undefined && data.refS3 !== null && data.refS3 !== "") {
      this.datosEdit.refS3 = data.refS3;
    }

    let fecha = new Date(data.fechaPublicacion);
    this.datosEdit.fechaPublicacion = fecha;
    this.date.year = fecha.getFullYear();
    this.date.month = fecha.getMonth() + 1;
    this.date.day = fecha.getDate();
    this.horaEntradaEdit.hour = fecha.getHours();
    this.horaEntradaEdit.minute = fecha.getMinutes();
    this.datosEdit.contenido = data.contenido;
    this.muestraEdit = true;
  }

  guardarNoticia() {
    this.submitted = true;
    if (this.frmNoticia.invalid) {
      return false;
    }
    this.spinerGuardar = true;

    const noticia = this.frmNoticia.value;
    noticia.createdAt = new Date();
    let fechaPubli = new Date(noticia.fechaPublicacion);
    fechaPubli.setHours(this.horaEntrada.hour, this.horaEntrada.minute);
    let idImagen = "noticia-" + this.makeid(8);
    noticia.fechaPublicacion = fechaPubli;
    noticia.refS3 = idImagen;
    let self = this;
    noticia.Comentarios = [];
    if (this.contenidoFile !== undefined) {
      let file = this.contenidoFile;
      let ext = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: idImagen + "." + ext,
        ContentType: file.type,
        Body: file
      };
      let options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1
        // Give the owner of the bucket full control
      };

      let uploader = this.bucket.upload(params, options, function(err, data) {
        if (err) {
          
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          return false;
        }
        let timestamp = new Date().getTime();
        noticia.imagen = data.Location.toString()+"?t=" + timestamp;
        noticia.slug = self.utilService.generateSlug(noticia.titulo);
        let encontrado = false;
        for (let not of self.listaDeNoticias) {
          if (not.slug !== undefined && not.slug !== null){
            if (not.slug === noticia.slug) {
              encontrado = true;
            }
          }
        }
        if (encontrado) {
         noticia.slug = noticia.slug +'-' +self.makeid(6); 
        }
        self.mainService.post2("api/noticia", noticia).subscribe(res => {
          if (res.success) {
            self.showToast(
              "top-right",
              "success",
              "Éxito!",
              "Se creo la noticia con éxito!"
            );
            self.frmNoticia.reset();
            self.submitted = false;
            self.contenidoFile = undefined;
            self.getNoticias();
            const element: HTMLElement = document.getElementById(
              "btn-close"
            ) as HTMLElement;
            element.click();
          } else {
            self.showToast(
              "top-right",
              "warning",
              "Érror!",
              "Ocurrio un error por favor intenta de nuevo!"
            );
          }
          self.spinerGuardar = false;
        });
      });
      uploader.on("httpUploadProgress", function(event) {
        console.log((event.loaded * 100) / event.total);
      });
    } else {
      self.spinerGuardar = false;
      self.submitted = false;
      self.showToast(
        "top-right",
        "warning",
        "Érror!",
        "Debe seleccionar la imagen principal de la noticia!"
      );
    }
  }

  modificarNoticia() {
    this.submitted2 = true;
    if (this.frmNoticiaEdit.invalid) {
      return false;
    }
    this.spinerEdit = true;
    const noticia = this.frmNoticiaEdit.value;
    noticia._id = this.datosEdit._id;
    let fechaPubli = new Date(this.datosEdit.fechaPublicacion);
    fechaPubli.setHours(this.horaEntradaEdit.hour, this.horaEntradaEdit.minute);
    let idImagen = this.datosEdit.refS3;
    noticia.fechaPublicacion = fechaPubli;
    noticia.refS3 = idImagen;
    let self = this;
    if (this.contenidoFileE !== undefined) {
      let file = this.contenidoFileE;
      let ext = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: idImagen + "." + ext,
        ContentType: file.type,
        Body: file
      };
      let options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1
        // Give the owner of the bucket full control
      };

      let uploader = this.bucket.upload(params, options, function(err, data) {
        if (err) {
          
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          return false;
        }
        let timestamp = new Date().getTime();
        noticia.imagen = data.Location.toString()+"?t=" + timestamp;
        noticia.slug = self.utilService.generateSlug(noticia.titulo);
        let encontrado = false;
        for (let not of self.listaDeNoticias) {
          if(not._id !== noticia._id){
            if (not.slug !== undefined && not.slug !== null){
              if (not.slug === noticia.slug) {
               encontrado = true;
              }
            }
          }
        }
        if (encontrado) {
         noticia.slug = noticia.slug +'-' +self.makeid(6); 
        }
        self.mainService
          .put2("api/noticia/" + noticia._id, noticia)
          .subscribe(res => {
            if (res.success) {
              self.showToast(
                "top-right",
                "success",
                "Éxito!",
                "Se modifico la noticia con éxito!"
              );
              self.frmNoticiaEdit.reset();
              self.submitted2 = false;
              self.datosEdit = {
                _id: "",
                titulo: "",
                autor: "",
                contenido: "",
                estado: "",
                fechaPublicacion: new Date(),
                imagen: "",
                video: "",
                refS3: ""
              };
              self.contenidoFileE = undefined;
              self.getNoticias();
              const element: HTMLElement = document.getElementById(
                "btn-close-edit"
              ) as HTMLElement;
              element.click();
            } else {
              self.showToast(
                "top-right",
                "warning",
                "Érror!",
                "Ocurrio un error por favor intenta de nuevo!"
              );
            }
            self.spinerEdit = false;
          });
      });
      uploader.on("httpUploadProgress", function(event) {
        console.log((event.loaded * 100) / event.total);
      });
    } else {
      noticia.imagen = this.datosEdit.imagen;
      noticia.slug = this.utilService.generateSlug(noticia.titulo);
      let encontrado = false;
      for (let not of this.listaDeNoticias) {
        if(not._id !== noticia._id){
          if (not.slug !== undefined && not.slug !== null){
            if (not.slug === noticia.slug) {
              encontrado = true;
            }
          }
        }
      }
      if (encontrado) {
        noticia.slug = noticia.slug +'-' +this.makeid(6); 
      }
      this.mainService
        .put2("api/noticia/" + noticia._id, noticia)
        .subscribe(res => {
          if (res.success) {
            self.showToast(
              "top-right",
              "success",
              "Éxito!",
              "Se modifico con éxito el registro!"
            );
            self.frmNoticiaEdit.reset();
            self.submitted2 = false;
            self.datosEdit = {
              _id: "",
              titulo: "",
              autor: "",
              contenido: "",
              estado: "",
              fechaPublicacion: new Date(),
              imagen: "",
              video: "",
              refS3: ""
            };
            self.getNoticias();
            const element: HTMLElement = document.getElementById(
              "btn-close-edit"
            ) as HTMLElement;
            element.click();
          } else {
            self.showToast(
              "top-right",
              "warning",
              "Érror!",
              "Ocurrio un error por favor intenta de nuevo!"
            );
          }
          self.spinerEdit = false;
        });
    }
  }

  public abrirArchivo() {
    const element: HTMLElement = document.getElementById(
      "imagen-noticia"
    ) as HTMLElement;
    element.click();
  }
  public abrirArchivoE() {
    const element: HTMLElement = document.getElementById(
      "imagen-noticiaE"
    ) as HTMLElement;
    element.click();
  }
  public selectFileE(event1) {
    this.contenidoFileE = event1.target.files[0];
    let archivos = event1.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.previewE = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }

  public selectFile(event1) {
    this.contenidoFile = event1.target.files[0];
    let archivos = event1.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.preview = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }

  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /** 
   * Metodo que hace el llamado al servico de exportat a xls
   */
  exportarXls(){
    this.exportando = true;
    let datos = {
      listado: this.listaDeNoticias,
      primeraFila: ['Titulo', 'Autor', 'Contenido', 'Estado', 'Fecha de publicación'],
      campos: ['titulo', 'autor', 'contenido', 'estado', 'fechaPublicacion'],
      nombre: 'Lista-de-noticias'
    };
    if (this.utilService.reporteXls(datos)) {
      this.exportando = false;
    }
  }
}
