import { MainService } from "./../services/main.service";
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild
} from "@angular/core";
import { NbThemeService, NbColorHelper, NbCalendarRange } from "@nebular/theme";
import { icons } from "eva-icons";
import * as moment from "moment";
import * as _ from "lodash";
import Chart from "chart.js";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import * as Honeycode from "aws-sdk/clients/honeycode";

type ChartData = [{ date; value; count }];
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  /** Variable de contro para el spinner de carga */
  spinerGrafica = true;
  /** Objeto de informacion de grafica */
  data: any;
  /** Lista de usuarios del sistema */
  usuario: any[];
  /** Lista de noticias en el sistema */
  noticias: any[];
  /** Lista de ofertas en el sistema */
  ofertas: any[];
  ofertasFiltradas: any[];
  /** Lista de visitas en el sistema */
  visitas: any[];
  /** Referencia al grafio 1 */
  @ViewChild("grafico1", { static: false }) grafico1: ElementRef;
  /** Verifica carga inicial */
  cargaCompleta = false;
  /** Datas para graficas de visitas*/
  dataVisitasModulo: any;
  dataVisitasModuloPlataforma: any;
  dataVisitasModuloWeb: any;
  dataVisitasModuloMovil: any;
  visitasAgrupadas: any[];
  visitasAgrupadasPlataforma: any[];
  visitasAgrupadasWeb: any[];
  visitasAgrupadasMovil: any[];
  spinerGVA = true;
  spinerGVAPlataforma = true;
  spinerGVAMovil = true;
  spinerGVAWeb = true;
  cantidadVisitas=0;
  /** Graficas para usuario */
  usuarios: any;
  cantidadUsuarios
  dataUsuarios: any;
  spinerGUsuario = true;
  /** Graficas para noticias */
  noticiasOrdenadas: any;
  noticiasFiltrada: any;
  dataNoticias: any;
  spinerGNoticias = true;
  /** Graficas para ofertas */
  ofertasOrdenadas: any;
  dataOfertas: any;
  spinerGOfertas = true;
  /** Opciones para la graficas */
  options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontColor: "rgb(255, 99, 132)"
      }
    }
  };
  /** Opciones para la graficas */
  options2 = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontColor: "rgb(255, 99, 132)"
      },
      position: 'right'
    }
  };
  /** Rango para filtrar las graficas */
  range: NbCalendarRange<Date>;
  hoveredDate: NgbDate | null = null;

  fromDate: any | null = {year: 2020, month: 8, day: 1};
  toDate: NgbDate | null = this.calendar.getToday();

  fromDateN: Date;
  toDateN: Date;

  constructor(
    private theme: NbThemeService,
    public mainService: MainService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {  
    
    this.fromDateN = new Date('2020-08-01');
    this.toDateN = new Date();
    this.toDate.year = this.toDateN.getFullYear();
    this.toDate.month = this.toDateN.getMonth()+1;
    this.toDate.day= this.toDateN.getDate();
    this.fromDate.year= 2020; 
    this.fromDate.month= 8;
    this.fromDate.day= 1;
    
    console.log(this.fromDate, this.toDate);
  }

  ngOnInit() {
    this.getNoticias();
  }
  /**
   * Funcion para obtener lista de usuarios completa del sistema
   */
  getUsuarios() {
    let diaDesde = this.fromDate.day.toString();
    if (this.fromDate.day < 10) {
      diaDesde = '0' +this.fromDate.day;
    }
    let diaHasta = this.toDate.day.toString();
    if (this.toDate.day < 10) {
      diaHasta = '0' +this.toDate.day;
    }

    let mesDesde = this.toDate.month.toString();
    if (this.fromDate.month < 10) {
      mesDesde = '0' +this.fromDate.month;
    }
    let mesHasta = this.toDate.month.toString();
    if (this.toDate.month < 10) {
      mesHasta = '0' +this.toDate.month;
    }
    let desde = this.fromDate.year + '-' + mesDesde + '-' + diaDesde;
    let hasta = this.toDate.year + '-' + mesHasta + '-' + diaHasta;
    let api = "api/userDashboard/"+desde+"/"+hasta ;
    console.log(api);
    let desdeN = this.fromDateN;
    console.log(desdeN);
    this.mainService.get2(api).subscribe(usuarios => {
      this.cantidadUsuarios = 0;
      this.usuarios = usuarios;
      for (let user of this.usuarios){
        this.cantidadUsuarios = this.cantidadUsuarios + user.count;
      }
      this.setGraficaUsuarioTipo();
    });
  }
  /**
   * Genera dataSet para grafica de usuarios por tipo
   */
  setGraficaUsuarioTipo() {
    let labels = [];
    let data = [];
    for (let item of this.usuarios) {
      labels.push(item._id);
      data.push(item.count);
    }
    this.dataUsuarios = this.graficoBar({
      labels: labels,
      data: data,
      titulo: "Tipos de usuarios"
    });
    this.spinerGUsuario = false;
  }
  /**
   * Funcion para obtener lista completa de noticias en el sistema
   */
  getNoticias() {
    this.mainService.get2("api/noticiaList").subscribe(noticias => {
      this.noticias = noticias;
      this.noticiasFiltrada = this.noticias;
      this.getOfertas();
    });
  }
  /**
   * Genera dataSet de likes por noticias
   */
  setGraficaNoticia() {
    let labels = [];
    let data = [];
    for (let item of this.noticiasOrdenadas) {
      labels.push(item.titulo.substring(0, 10) + "...");
      data.push(item.likes);
    }
    this.dataNoticias = this.graficoBar({
      labels: labels,
      data: data,
      titulo: "Likes por noticia"
    });
    this.spinerGNoticias = false;
  }
  /**
   * Funcion para obtener lista de ofertas en el sistema
   */
  getOfertas() {
    this.mainService.get2("api/ofertasDashboard").subscribe(ofertas => {
      this.ofertas = ofertas;
      this.ofertasFiltradas = this.ofertas;
      this.filtar();
    });
  }
  /**
   * Genera dataSet de likes por noticias
   */
  setGraficaOferta() {
    let labels = [];
    let data = [];
    for (let item of this.ofertasOrdenadas) {
      labels.push(item.nombre.substring(0, 10) + "...");
      data.push(item.likes);
    }
    this.dataOfertas = this.graficoBar({
      labels: labels,
      data: data,
      titulo: "Likes por ofertas"
    });
    this.spinerGOfertas = false;
  }
  /**
   * Funcion para obtener lista da visitas por modulo en el sistema
   */
  getVisitas() {
    this.spinerGVA=true;
    this.spinerGVAPlataforma = true;
    this.spinerGVAMovil = true;
    this.spinerGVAWeb = true;
    let diaDesde = this.fromDate.day.toString();
    if (this.fromDate.day < 10) {
      diaDesde = '0' +this.fromDate.day;
    }
    let diaHasta = this.toDate.day.toString();
    if (this.toDate.day < 10) {
      diaHasta = '0' +this.toDate.day;
    }

    let mesDesde = this.toDate.month.toString();
    if (this.fromDate.month < 10) {
      mesDesde = '0' +this.fromDate.month;
    }
    let mesHasta = this.toDate.month.toString();
    if (this.toDate.month < 10) {
      mesHasta = '0' +this.toDate.month;
    }
    let desde = this.fromDate.year + '-' + mesDesde + '-' + diaDesde;
    let hasta = this.toDate.year + '-' + mesHasta + '-' + diaHasta;
    let periodo = "/"+desde+"/"+hasta ;
    this.mainService.get2("api/visitaByModulo"+periodo).subscribe(visitas => {
      this.visitas = visitas;
      this.visitasAgrupadas = visitas;
      this.setGraficaVisitasModulo();
      this.getVisitasPlataforma();
    });
  }
  /**
   * Generar garfico de visita por plataforma
   */
  getVisitasPlataforma(){
    let diaDesde = this.fromDate.day.toString();
    if (this.fromDate.day < 10) {
      diaDesde = '0' +this.fromDate.day;
    }
    let diaHasta = this.toDate.day.toString();
    if (this.toDate.day < 10) {
      diaHasta = '0' +this.toDate.day;
    }

    let mesDesde = this.toDate.month.toString();
    if (this.fromDate.month < 10) {
      mesDesde = '0' +this.fromDate.month;
    }
    let mesHasta = this.toDate.month.toString();
    if (this.toDate.month < 10) {
      mesHasta = '0' +this.toDate.month;
    }
    let desde = this.fromDate.year + '-' + mesDesde + '-' + diaDesde;
    let hasta = this.toDate.year + '-' + mesHasta + '-' + diaHasta;
    let periodo = "/"+desde+"/"+hasta ;
    this.mainService.get2("api/visitaByplataforma"+periodo).subscribe(visitas => {
      this.cantidadVisitas = 0;
      for (let vis of visitas) {
        this.cantidadVisitas = this.cantidadVisitas + vis.count;
      }
      this.visitasAgrupadasPlataforma = visitas;
      this.setGraficaVisitasPlataforma();
    });
  }
  /**
   * Generar garfico de visita por plataforma web
   */
   getVisitasWeb(){
    let diaDesde = this.fromDate.day.toString();
    if (this.fromDate.day < 10) {
      diaDesde = '0' +this.fromDate.day;
    }
    let diaHasta = this.toDate.day.toString();
    if (this.toDate.day < 10) {
      diaHasta = '0' +this.toDate.day;
    }

    let mesDesde = this.toDate.month.toString();
    if (this.fromDate.month < 10) {
      mesDesde = '0' +this.fromDate.month;
    }
    let mesHasta = this.toDate.month.toString();
    if (this.toDate.month < 10) {
      mesHasta = '0' +this.toDate.month;
    }
    let desde = this.fromDate.year + '-' + mesDesde + '-' + diaDesde;
    let hasta = this.toDate.year + '-' + mesHasta + '-' + diaHasta;
    let periodo = "/"+desde+"/"+hasta ;
    this.mainService.get2("api/visitaByPlataformaModulo"+periodo+"/web").subscribe(visitas => {
      this.visitasAgrupadasWeb = visitas;
      this.setGraficaVisitasWeb();
      this.getVisitasMovil();
    });
  }
  /**
   * Generar garfico de visita por plataforma web
   */
   getVisitasMovil(){
    let diaDesde = this.fromDate.day.toString();
    if (this.fromDate.day < 10) {
      diaDesde = '0' +this.fromDate.day;
    }
    let diaHasta = this.toDate.day.toString();
    if (this.toDate.day < 10) {
      diaHasta = '0' +this.toDate.day;
    }

    let mesDesde = this.toDate.month.toString();
    if (this.fromDate.month < 10) {
      mesDesde = '0' +this.fromDate.month;
    }
    let mesHasta = this.toDate.month.toString();
    if (this.toDate.month < 10) {
      mesHasta = '0' +this.toDate.month;
    }
    let desde = this.fromDate.year + '-' + mesDesde + '-' + diaDesde;
    let hasta = this.toDate.year + '-' + mesHasta + '-' + diaHasta;
    let periodo = "/"+desde+"/"+hasta ;
    this.mainService.get2("api/visitaByPlataformaModulo"+periodo+"/movil").subscribe(visitas => {
      this.visitasAgrupadasMovil = visitas;
      this.setGraficaVisitasMovil();
    });
  }
  /**
   * Genera dataSet para grafica de visitas por modulo
   */
  setGraficaVisitasModulo() {
    let labels = [];
    let data = [];
    for (let item of this.visitasAgrupadas) {
      labels.push(item._id);
      data.push(item.count);
    }
    this.dataVisitasModulo = this.graficoPie({ labels: labels, data: data });
    this.spinerGVA = false;
  }
  /**
   * Genera dataSet para grafica de visitas por plataforma
   */
  setGraficaVisitasPlataforma() {
    let labels = [];
    let data = [];
    for (let item of this.visitasAgrupadasPlataforma) {
      labels.push(item._id);
      data.push(item.count);
    }
    this.dataVisitasModuloPlataforma = this.graficoPie({
      labels: labels,
      data: data
    });
    this.spinerGVAPlataforma = false;
    this.getVisitasWeb();
  }
  /**
   * Genera dataSet para grafica de visitas en la plataforma movil
   */
  setGraficaVisitasMovil() {
    let labels = [];
    let data = [];
    for (let item of this.visitasAgrupadasMovil) {
      labels.push(item._id);
      data.push(item.count);
    }
    this.dataVisitasModuloMovil = this.graficoPie({
      labels: labels,
      data: data
    });
    this.spinerGVAMovil=false;
  }
  /**
   * Genera dataSet para grafica de visitas en la plataforma web
   */
  setGraficaVisitasWeb() {
    let labels = [];
    let data = [];
    for (let item of this.visitasAgrupadasWeb) {
      labels.push(item._id);
      data.push(item.count);
    }
    this.dataVisitasModuloWeb = this.graficoPie({ labels: labels, data: data });
    this.spinerGVAWeb=false;
  }
  /**
   * Genera dataSet de grafico de torta
   * @param param0 Parametros para genrear grafioc pie
   */
  graficoPie({ labels, data }) {
    let datos = {
      datasets: [
        {
          data: data,
          backgroundColor: [
            "rgba(2, 137, 190, 1)",
            "rgba(233, 28, 114, 1)",
            "rgba(128, 128, 128, 0.5)",
            "rgba(15, 206, 210, 0.19)",
            "rgba(88, 10, 114, 1)",
            "rgba(13, 128, 114, 1)",
            "rgba(233, 28, 114, 1)"
          ],
          borderColor: "#ffffff"
        }
      ],
      labels: labels
    };
    return datos;
  }
  /**
   * Genera dataSet de grafico de lineas
   * @param param0 Parametros para generar grafico de lineas
   */
  graficoBar({ labels, data, titulo }) {
    let datos = {
      labels: labels,
      datasets: [
        {
          label: titulo,
          data: data,
          barPercentage: 0.5,
          barThickness: 6,
          maxBarThickness: 8,
          minBarLength: 2,
          backgroundColor: [
            "rgba(2, 137, 190, 1)",
            "rgba(233, 28, 114, 1)",
            "rgba(128, 128, 128, 0.5)",
            "rgba(15, 206, 210, 0.19)",
            "rgba(88, 10, 114, 1)",
            "rgba(13, 128, 114, 1)",
            "rgba(233, 28, 114, 1)"
          ],
          borderColor: "#ffffff"
        }
      ]
    };
    return datos;
  }
  /**
   * Metodo para asignar valores a los modelos cuando se selecciona fecha en el calendario
   * @param date Fecha seleccionada
   */
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
  /**
   * Metodo para aplicar el metodo de filtro por fecha en todas las graficas y estadisticas
   */
  filtar() {
    this.toDate.year = this.toDateN.getFullYear();
    this.toDate.month = this.toDateN.getMonth()+1;
    this.toDate.day= this.toDateN.getDate();
    this.fromDate.year= this.fromDateN.getFullYear(); 
    this.fromDate.month= this.fromDateN.getMonth()+1;
    this.fromDate.day= this.fromDateN.getDate();
    console.log(this.fromDate, this.toDate);
    
    this.getUsuarios();
    /** Actualizar noticias */
    this.noticiasFiltrada = this.filterDates(this.fromDate, this.toDate, this.noticias);
    this.noticiasOrdenadas = _.orderBy(this.noticiasFiltrada, ["likes"], ["desc"]);
    this.noticiasOrdenadas = this.noticiasOrdenadas.splice(0, 10);
    this.setGraficaNoticia();
    /** Actualizar grafica de ofertas */
    this.ofertasFiltradas = this.filterDates(this.fromDate, this.toDate, this.ofertas);
    this.ofertasOrdenadas = _.orderBy(this.ofertasFiltradas, ["likes"], ["desc"]);
    this.ofertasOrdenadas = this.ofertasOrdenadas.splice(0, 10);
    this.setGraficaOferta();
    /** Actualizar grafico de visitas */
    this.getVisitas();
  }
  public filterDates(dateStart, dateEnd, arregloParametro) {
    dateStart = dateStart.year + "-" + dateStart.month + "-" + dateStart.day;
    dateEnd = dateEnd.year + "-" + dateEnd.month + "-" + dateEnd.day;
    dateStart = new Date(dateStart);
    dateEnd = new Date(dateEnd);
    arregloParametro = arregloParametro.filter(a => {
      const activityDate = new Date(a.createdAt);
      const x = moment(activityDate, "YYYY-MM-DD").toDate();
      if (x >= dateStart && x <= dateEnd) {
        return true;
      } else {
        return false;
      }
    });
    return arregloParametro;
  }
}
