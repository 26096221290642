import { UtilService } from './../services/util.service';
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import {Router } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import * as _ from "lodash";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"]
})
export class BannerComponent implements OnInit {
  /** Variable de control de carga de informacion */
  cargaCompleta = false;
  /*  Variables de referencia para el modal de edicion de la informacion*/
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /** Identificador de formulario de edicion */
  frm: FormGroup;
  /** Variables para el control de envio de datos del formumario */
  submitted2 = false;
  spinerEdit = false;
  /** Configuracion de tabla de datos de banners creados  */
  settings = {
    hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [{ name: "edit", title: '<i class="nb-compose"></i> ' }]
    },
    columns: {
      nombre: { title: "Nombre" },
      imagen: {
        title: "Imagen",
        type: "html",
        valuePrepareFunction: img => {
          if (img !== null && img !== undefined && img !== "") {
            if (img.url !== '') {
              return '<img src="' + img.url + '" class="foto-lista" />';
            }
          }
          return '<img src="/assets/admin/sin-imagen.jpg" class="foto-lista" />';
        }
      }
    }
  };
  /** Arreglo donde cargaremos los datos de los banners registrado */
  lista = [];
  /** Varialbe para almacenar la informacion de la imagen a cargar */
  contenidoFileE: any = undefined;
  /** Variable de control para la vista previa de la imagen a cargar */
  preview: any = null;
  /** Modelo de datos para la edicion de banner seleccionado */
  datosEdit = {
    _id: "",
    nombre: "",
    imagen: { url: "", refS3: "" }
  };
  /** Source para cargar los datos a mostar en el DataTable */
  public source: LocalDataSource;
  constructor(
    public fb: FormBuilder,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    public utilService: UtilService
  ) {
    this.frm = this.fb.group({
      nombre: ["", [Validators.required]],
      _id: ["", []]
    });
  }

  ngOnInit() {
    this.getBanners();
  }
  /**
   * Funcion para resumir metodo de obtener valores del formuario de edicion
   */
  get f2() {
    return this.frm.controls;
  }
  /**
   * Obtener lista de banner registrados
   */
  getBanners() {
    this.mainService.get({ api: "api/banner" }).subscribe(data => {
      for (let d of data) {
        if (d.imagen === undefined || d.imagen === null || d.imagen === "") {
          d.imagen = { url: "/assets/admin/sinfoto.png", refS3: "" };
        }
      }
      this.lista = data;
      this.source = new LocalDataSource(this.lista);
      this.cargaCompleta = true;
    });
  }
  /**
   * Despliega el modal
   * @param dialog Identificador del modal a desplegar
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.openEdit(this.dialogEdit, event.data);
        break;
    }
  }
  /**
   * Asignar los datos del objeto seleccionado en el data-table al modelo de edicion datosEdit
   * @param dialog Referencia al modal del formulario de edicion
   * @param data Objeto banner seleccionada desde el data-table
   */
  openEdit(dialog, data) {
    this.datosEdit._id = data._id;
    this.datosEdit.nombre = data.nombre;
    this.datosEdit.imagen = data.imagen;
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /**
   * Inicia proceso para la modificacion de los datos del banner, evalua si se debe modificar la imagen o no
   */
  public async modificar() {
    this.submitted2 = true;
    const banner = this.frm.value;
    if (this.frm.invalid) {
      return false;
    }
    this.spinerEdit = true;
    if (this.contenidoFileE !== undefined) {
      let idBanner = "banner-" + banner._id;
      if (this.datosEdit.imagen.refS3 !== "") {
        idBanner = this.datosEdit.imagen.refS3;
      }
      let file = this.contenidoFileE;
      let objCarga = await this.utilService.cargarImagen({ idS3: idBanner, file: file});
      //@ts-ignore
      if (objCarga.success) {
        this.datosEdit.imagen = {
          //@ts-ignore
          url: objCarga.url,
          refS3: idBanner
        };
        this.ejecutarModificar();
      } else {
        this.utilService.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
        return false;
      }
    } else {
      this.ejecutarModificar();
    }
  }
  /**
   * Ejecuta los llamados al servio de modificar datos del banner
   * @param param0 Datos del banner a modificar
   */
  ejecutarModificar() {
    this.mainService.put2("api/banner/" + this.datosEdit._id, this.datosEdit).subscribe(res => {
      if (res.success) {
        this.utilService.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se modifico con éxito el registro!"
        );
        this.reiniciarFormulario();
        const element: HTMLElement = document.getElementById(
          "btn-close-edit"
        ) as HTMLElement;
        element.click();
      } else {
        this.utilService.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
      this.spinerEdit = false;
    });
  }
  /**
   * Reiniciar formulario modelos de los datos edicion
   */
  reiniciarFormulario() {
    this.frm.reset();
    this.submitted2 = false;
    this.datosEdit = {
      _id: "",
      nombre: "",
      imagen: { url: "", refS3: "" }
    };
    this.preview = null;
    this.getBanners();
    this.contenidoFileE = undefined;
  }

  /** funciones para carga de imagen */
  public abrirArchivo() {
    const element: HTMLElement = document.getElementById(
      "imagen"
    ) as HTMLElement;
    element.click();
  }
  public inputImagen(event) {
    this.contenidoFileE = event.target.files[0];
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.preview = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
}
