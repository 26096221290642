import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NbMenuItem } from "@nebular/theme";

export const Menu: NbMenuItem[] = [
  {
    title: "Estadisticas",
    icon: "fa fa-line-chart icono-menu",
    home: true,
    link: "/panel/home",
    pathMatch: "full",
  },
  {
    title: "Usuarios",
    icon: "fa fa-user icono-menu",
    link: "/panel/usuarios",
    pathMatch: "full",
  },
  {
    title: "Actualidad",
    expanded: true,
    icon: "fa fa-newspaper-o icono-menu",
    pathMatch: "/panel/full",
    children: [
      {
        title: "Crear",
        icon: "fa fa-edit icono-menu",
        link: "/panel/noticias",
        pathMatch: "full",
      },
      {
        title: "Banners",
        icon: "fa fa-file-image-o icono-menu",
        link: "/panel/banner",
        pathMatch: "full",
      },
    ],
  },
  {
    title: "Juego",
    icon: "fa fa-gamepad icono-menu",
    link: "/panel/juego",
    pathMatch: "full",
  },
  // {
  //   title: 'Horóscopo',
  //   icon: 'fa fa-arrows icono-menu',
  //   link: '/panel/horoscopo',
  //   pathMatch: "full",
  // },
  {
    title: "Yo aprendo",
    icon: "fa fa-youtube-play icono-menu",
    link: "/panel/yoaprendo",
    pathMatch: "full",
  },
  {
    title: "Proveedores",
    icon: "fa fa-cog icono-menu",
    expanded: true,
    pathMatch: "full",
    children: [
      {
        title: "Crear proveedor",
        icon: "fa fa-edit icono-menu",
        link: "/panel/proveedores",
        pathMatch: "full",
      },
      {
        title: "Categorias",
        icon: "fa fa-archive icono-menu",
        link: "/panel/categoria",
        pathMatch: "full",
      },
    ],
  },
  {
    title: "Feria",
    icon: "fa fa-map-marker icono-menu",
    expanded: true,
    pathMatch: "full",
    children: [
      {
        title: "Configurador",
        icon: "fa fa-gear icono-menu",
        link: "/panel/config-gransalon",
        pathMatch: "full",
      },
      {
        title: "Expositores",
        icon: "fa fa-edit icono-menu",
        link: "/panel/gransalon",
        pathMatch: "full",
      },
    ],
  },
  // {
  //   title: 'Gran Salón Ferretero',
  //   icon: 'fa fa-map-marker icono-menu',
  //   link: '/panel/gransalon',
  //   pathMatch: "full",
  // },
  // {
  //   title: 'Ferreofertas',
  //   icon: 'fa fa-percent icono-menu',
  //   link: 'ofertas'
  // },
  {
    title: "Premio",
    icon: "fa fa-percent icono-menu",
    link: "/panel/premio",
    pathMatch: "full",
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class MenuModule {}
