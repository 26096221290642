import { UtilService } from "./../services/util.service";
import { ProveedoresInterface } from "./../../model/proveedores";
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
type AOA = any[][];
import * as S3 from "aws-sdk/clients/s3";
import { CategoriaInterface } from "src/app/model/categorias";

@Component({
  selector: "app-proveedores",
  templateUrl: "./proveedores.component.html",
  styleUrls: ["./proveedores.component.scss"],
})
export class ProveedoresComponent implements OnInit {
  /** Credenciales para la carga de imagenes en amazong */
  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1",
  });
  /** Bandera de control para la carga inicial */
  cargaCompleta = false;
  /*  Para el modal variables de referencia a los modal de los formularios */
  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  @ViewChild("dialogImportar", { static: true }) dialogImportar: ElementRef;
  /** Formularios de crear y editar categoria */
  frm: FormGroup;
  frmCrear: FormGroup;
  /** Variables de control de envio de informacion en los formularios */
  submitted2 = false;
  spinerEdit = false;
  submitted = false;
  spinerGuardar = false;
  /*** Configuracion de la informacion a mostrar en la tabla de categorias **/
  settings = {
    //hideSubHeader: false,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nombre: { title: "Nombre", filter: true },
      categoria: {
        title: "Categoría",
        type: "text",
        filter: false,
        valuePrepareFunction: (cat) => {
          return this.mapingCategoria(cat);
        },
      },
      direccion: { title: "Dirección", filter: true },
      telefono: { title: "Teléfono", filter: true },
      ciudad: { title: "Ciudad", filter: true },
      pagina: { title: "Página", filter: true },
      leads: { title: "Leads", filter: true },
      email: { title: "Email", filter: true },
      destacado: { title: "Destacado", filter: true },
      logo: {
        title: "Logo",
        filter: false,
        type: "html",
        valuePrepareFunction: (img) => {
          if (img !== null && img !== undefined && img !== "") {
            return '<img src="' + img + '" class="foto-lista" />';
          }
          return '<img src="/assets/admin/sin-imagen.jpg" class="foto-lista" />';
        },
      },
    },
  };
  /** Lista de categorias registradas */
  lista = [];
  /** Objeto de control para los datos de edicion */
  datosEdit: ProveedoresInterface = {
    _id: "",
    nombre: "",
    categoria: "",
    direccion: "",
    telefono: "",
    ciudad: "",
    pagina: "",
    leads: 0,
    email: "",
    destacado: false,
    caducidad: "",
  };
  /** Source a asignar la lista de categorias y mostrar en la tabla */
  public source: LocalDataSource;
  /** Datos a importar */
  listaImportar: any[] = [];
  /** Imagen del icono */
  logo: any = null;
  refS3: string = "";
  preview: any = null;
  previewE: any = null;
  /** data de imagen */
  contenidoFile: any;
  contenidoFileE: any;
  /** variable para modelos de destacado */
  destacado: boolean = false;
  destacadoE: boolean = false;
  /** Lista de categorias registradas */
  listaCategorias: CategoriaInterface[] = [];
  inputFile: any;
  categoriaImportar: string = "";
  /** Cargando exportar */
  exportando = false;
  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    private rutaActiva: ActivatedRoute,
    public utilService: UtilService
  ) {
    this.frm = this.fb.group({
      nombre: ["", [Validators.required]],
      categoria: ["", [Validators.required]],
      direccion: ["", [Validators.required]],
      ciudad: ["", [Validators.required]],
      pagina: ["", []],
      telefono: ["", []],
      leads: ["", []],
      email: ["", []],
      _id: ["", []],
    });
    this.frmCrear = this.fb.group({
      nombre: ["", [Validators.required]],
      categoria: ["", [Validators.required]],
      direccion: ["", [Validators.required]],
      ciudad: ["", [Validators.required]],
      pagina: ["", []],
      telefono: ["", []],
      leads: [0, []],
      email: ["", []],
    });
  }

  ngOnInit() {
    this.getCategorias();
  }
  /*** Funcionea para abreviar la forma de obtener los datos de los formularios */
  get f2() {
    return this.frm.controls;
  }
  get f() {
    return this.frmCrear.controls;
  }
  /**
   * Funcion para obtener listado de categorias desde el servicio
   */
  getCategorias() {
    this.mainService.get({ api: "api/categorias" }).subscribe((data) => {
      this.listaCategorias = data;
      this.getProveedores();
    });
  }
  /**
   * Funcion para obtener listado de proveedores
   */
  getProveedores() {
    this.mainService.get({ api: "api/proveedores" }).subscribe((data) => {
      this.lista = data;
      this.source = new LocalDataSource(this.lista);
      this.cargaCompleta = true;
    });
  }
  /** Para modal **/
  crear() {
    this.openDialogSave(this.dialog);
  }
  modalImportar() {
    this.openDialogSave(this.dialogImportar);
  }
  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.openEdit(this.dialogEdit, event.data);
        break;
      case "delete":
        this.eliminarCategoria(event);
        break;
    }
  }
  /**
   * Eliminar categoria
   * @param even Datos de categoria a eliminar
   */
  eliminarCategoria(even) {
    Swal.fire({
      title: "¿Seguro que desea eliminar el proveedor?",
      text: "Los datos borrados no podrar ser recuperados!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No eliminar!",
    }).then((result) => {
      if (result.value) {
        this.cargaCompleta = false;
        this.mainService
          .delete2("api/proveedores/" + even.data._id)
          .subscribe((res) => {
            this.getProveedores();
            //this.cargaCompleta = true;
            if (res) {
              Swal.fire(
                "Se elimino con exito!",
                "El pŕoveedor fue eliminado.",
                "success"
              );
            }
          });
      }
    });
  }
  /** Levanta modal de edicion y carga los datos del objeto */
  openEdit(dialog, data) {
    this.datosEdit.nombre = data.nombre;
    this.datosEdit.email = data.email;
    this.datosEdit.ciudad = data.ciudad;
    this.datosEdit.direccion = data.direccion;
    this.datosEdit.telefono = data.telefono;
    this.datosEdit.leads = data.leads;
    this.datosEdit.pagina = data.pagina;
    this.datosEdit.categoria = data.categoria;
    this.destacadoE = data.destacado;
    this.datosEdit.caducidad = data.caducidad;
    this.datosEdit._id = data._id;
    this.datosEdit.logo = "/assets/admin/sin-foto.jpg";
    this.datosEdit.refS3 = undefined;
    console.log(data.logo);
    if (data.logo !== null && data.logo !== undefined) {
      console.log("entra");
      this.datosEdit.logo = data.logo;
      this.datosEdit.refS3 = data.refS3;
    }
    console.log(this.datosEdit);
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }
  /** Cambiar check de destacados */
  toogleDestacado() {
    this.destacado = !this.destacado;
  }
  toogleDestacadoE() {
    this.destacadoE = !this.destacadoE;
  }
  /**
   * Funcion que ejecuta el servicio de crear categoria de proveedores
   */
  ejucutarGuardar() {
    const proveedor = this.frmCrear.value;
    if (this.logo !== null) {
      proveedor.logo = this.logo;
      proveedor.refS3 = this.refS3;
    }
    proveedor.destacado = this.destacado;
    proveedor.caducidad = this.datosEdit.caducidad;
    this.mainService.post2("api/proveedores", proveedor).subscribe((res) => {
      this.spinerGuardar = false;
      if (res.success) {
        this.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se creo con éxito el registro!"
        );
        this.reiniciarFormularioCrear();
        const element: HTMLElement = document.getElementById(
          "btn-close"
        ) as HTMLElement;
        element.click();
      } else {
        this.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
    });
  }
  /**
   * Funcion que inicnia proceso de guardar categoria
   */
  guardar() {
    this.submitted = true;
    if (this.frmCrear.invalid) {
      return false;
    }
    this.spinerGuardar = true;
    this.guardarImagen("crear");
  }
  /**
   * Funcion que ejecuta el servicio de modificar categoria de proveedores
   */
  modificar() {
    this.submitted2 = true;
    if (this.frm.invalid) {
      return false;
    }
    this.spinerEdit = true;
    this.guardarImagen("editar");
  }
  finalizarModificar() {
    const proveedor = this.frm.value;
    proveedor.refS3 = this.datosEdit.refS3;
    proveedor.logo = this.datosEdit.logo;
    proveedor.destacado = this.destacadoE;
    proveedor.caducidad = this.datosEdit.caducidad;
    console.log(proveedor);
    this.mainService
      .put2("api/proveedores/" + this.datosEdit._id, proveedor)
      .subscribe((res) => {
        console.log(res);
        if (res.success) {
          this.showToast(
            "top-right",
            "success",
            "Éxito!",
            "Se modifico con éxito el registro!"
          );
          this.reiniciarFormulario();
          const element: HTMLElement = document.getElementById(
            "btn-close-edit"
          ) as HTMLElement;
          element.click();
        } else {
          this.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
        }
        this.spinerEdit = false;
      });
  }
  /** reiniciar formulario de edicion */
  reiniciarFormulario() {
    this.frm.reset();
    this.submitted2 = false;
    this.datosEdit = {
      _id: "",
      nombre: "",
      categoria: "",
      direccion: "",
      ciudad: "",
      pagina: "",
      leads: 0,
      email: "",
      destacado: false,
    };
    this.contenidoFileE = undefined;
    this.previewE = null;

    this.getProveedores();
  }
  /** reiniciar formulario de creacion */
  reiniciarFormularioCrear() {
    this.frmCrear.reset();
    this.submitted = false;
    this.getProveedores();
    this.logo = null;
    this.refS3 = "";
    this.preview = null;
    const element: HTMLElement = document.getElementById(
      "fileSelect"
    ) as HTMLElement;
    element.nodeValue = "";
    this.contenidoFile = undefined;
  }
  /**
   * Funcion para abrir el explorador de archivos
   */
  abrirImportar() {
    const element: HTMLElement = document.getElementById(
      "fileSelect"
    ) as HTMLElement;
    element.click();
  }
  /**
   * Funcion listener para la carga de archivo a importar
   * @param evt archivo a cargar
   */
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.listaImportar = <AOA>(
        XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, defval: null })
      );
    };
    reader.readAsBinaryString(target.files[0]);
  }
  /**
   * Inicia proceso de importar los datos
   */
  ejecutarImportar() {
    let datosImportar: ProveedoresInterface[] = [];
    this.listaImportar.splice(0, 1);
    for (let item of this.listaImportar) {
      datosImportar.push({
        nombre: item[0],
        direccion: item[1],
        telefono: item[2],
        pagina: item[3],
        ciudad: item[4],
        categoria: this.categoriaImportar,
      });
    }
    this.listaImportar = datosImportar;
    console.log(this.listaImportar);
    Swal.fire({
      title:
        "¿Seguro que desea importar " +
        this.listaImportar.length +
        " proveedores?",
      text: "Se importaran los proveedores dentro del archivo sin tener en cuenta duplicados!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, importar!",
      cancelButtonText: "No, importar!",
    }).then((result) => {
      if (result.value) {
        this.cargaCompleta = false;
        this.mainService
          .post2("api/proveedores/importar", this.listaImportar)
          .subscribe((res) => {
            this.cargaCompleta = true;
            this.getProveedores();
            this.listaImportar = [];
            this.categoriaImportar = "";
            const element: HTMLElement = document.getElementById(
              "btn-close-import"
            ) as HTMLElement;
            element.click();
            if (res) {
              Swal.fire(
                "Se importo con exito!",
                "Verifica la lista de proveedores.",
                "success"
              );
            }
          });
      }
    });
  }
  /** funciones para carga de logo */
  public abrirArchivo() {
    const element: HTMLElement = document.getElementById(
      "img-segundarias"
    ) as HTMLElement;
    element.click();
  }
  public inputImagen(event) {
    this.contenidoFile = event.target.files[0];
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.preview = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
  /** funciones para carga de icono edicion*/
  public abrirArchivoE() {
    const element: HTMLElement = document.getElementById(
      "img-segundariasE"
    ) as HTMLElement;
    element.click();
  }
  public inputImagenE(event) {
    this.contenidoFileE = event.target.files[0];
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.previewE = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
  /**
   * Funcion para crear codigo aleatorio para las referenicas de las imagenes
   * @param length longintud del codigo aleataorio a crear
   */
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Metodo para guardar las imagenes tanto al editar como al crear
   * @param tipo determina si la imagen es para editar o crear la noticia
   * @param pos Posicion dentro del arreglo que almacena las imagenes tanto en el crear como en el editar
   */
  guardarImagen(tipo) {
    let idImagen = "prov-" + this.makeid(8);
    let imagen = this.contenidoFile;
    if (tipo === "editar") {
      if (this.datosEdit.logo !== undefined && this.datosEdit.logo !== null) {
        if (this.datosEdit.refS3 !== undefined) {
          idImagen = this.datosEdit.refS3;
        }
      }
      imagen = this.contenidoFileE;
    }
    let self = this;
    if (imagen !== undefined) {
      let file = imagen;
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: idImagen,
        ContentType: file.type,
        Body: file,
      };
      let options = {
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
      };
      let uploader = this.bucket.upload(params, options, function (err, data) {
        if (err) {
          console.log("PERDIDAS");
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          self.spinerEdit = false;
          self.spinerGuardar = false;
          return false;
        }
        let timestamp = new Date().getTime();
        if (tipo === "crear") {
          self.logo = data.Location.toString() + "?t=" + timestamp;
          self.refS3 = idImagen;
          self.ejucutarGuardar();
        } else {
          self.datosEdit.logo = data.Location.toString() + "?t=" + timestamp;
          self.datosEdit.refS3 = idImagen;
          self.finalizarModificar();
        }
      });
      uploader.on("httpUploadProgress", function (event) {
        console.log("UPDATE");
        console.log(event);
        console.log((event.loaded * 100) / event.total);
      });
    } else {
      if (tipo === "crear") {
        self.ejucutarGuardar();
      } else {
        self.finalizarModificar();
      }
    }
  }

  /**  */
  mapingCategoria(id) {
    let cat = "";
    for (let item of this.listaCategorias) {
      if (item._id === id) {
        cat = item.nombre;
        break;
      }
    }
    return cat;
  }
  /**
   * Metodo que hace el llamado al servico de exportat a xls
   */
  exportarXls() {
    this.exportando = true;
    let datos = {
      listado: this.lista,
      primeraFila: [
        "Nombre",
        "Dirección",
        "Ciudad",
        "Pagina",
        "Teléfono",
        "Leads",
        "Email",
      ],
      campos: [
        "nombre",
        "direccion",
        "ciudad",
        "pagina",
        "telefono",
        "leads",
        "email",
      ],
      nombre: "Lista-de-proveedores",
    };
    if (this.utilService.reporteXls(datos)) {
      this.exportando = false;
    }
  }
}
