import { UtilService } from './../services/util.service';
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";

import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import { CategoriaInterface } from "src/app/model/categorias";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
type AOA = any[][];
import * as S3 from "aws-sdk/clients/s3";

@Component({
  selector: "app-categorias",
  templateUrl: "./categorias.component.html",
  styleUrls: ["./categorias.component.scss"]
})
export class CategoriasComponent implements OnInit {
  /** Credenciales para la carga de imagenes en amazong */
  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1"
  });
  /** Bandera de control para la carga inicial */
  cargaCompleta = false;
  /*  Para el modal variables de referencia a los modal de los formularios */
  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /** Formularios de crear y editar categoria */
  frm: FormGroup;
  frmCrear: FormGroup;
  /** Variables de control de envio de informacion en los formularios */
  submitted2 = false;
  spinerEdit = false;
  submitted = false;
  spinerGuardar = false;
  /*** Configuracion de la informacion a mostrar en la tabla de categorias **/
  settings = {
    //hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' }
      ]
    },
    columns: {
      nombre: { title: "Nombre", filter: true },
      icono: {
        title: 'Icono',
        filter: false,
        type: "html",
        valuePrepareFunction: img => {
          if (img !== null && img !== undefined && img !== '') {
            return '<img src="' + img + '" class="foto-lista" />';
          }
          return '<img src="/assets/admin/sin-imagen.jpg" class="foto-lista" />';
        }
      }
    }
  };
  /** Lista de categorias registradas */
  lista = [];
  /** Objeto de control para los datos de edicion */
  datosEdit: CategoriaInterface = {
    _id: "",
    nombre: ""
  };
  /** Source a asignar la lista de categorias y mostrar en la tabla */
  public source: LocalDataSource;
  /** Datos a importar */
  listaImportar: any[] = [];
  /** Imagen del icono */
  icono: any = null;
  refS3: string = "";
  preview: any = null;
  previewE: any = null;
  /** data de imagen */
  contenidoFile: any;
  contenidoFileE: any;
  /** Almacena archivo para importar */
  inputFile: any;
  /** Cargando exportar */
  exportando=false;
  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    private rutaActiva: ActivatedRoute,
    public utilService: UtilService
  ) {
    this.frm = this.fb.group({
      nombre: ["", [Validators.required]],
      _id: ["", []]
    });
    this.frmCrear = this.fb.group({
      nombre: ["", [Validators.required]]
    });
  }

  ngOnInit() {
    this.getCategorias();
  }
  /*** Funcionea para abreviar la forma de obtener los datos de los formularios */
  get f2() {
    return this.frm.controls;
  }
  get f() {
    return this.frmCrear.controls;
  }
  /**
   * Funcion para obtener listado de categorias desde el servicio
   */
  getCategorias() {
    this.mainService.get({ api: "api/categorias/nombre/1" }).subscribe(data => {
      this.lista = data;
      this.source = new LocalDataSource(this.lista);
      this.cargaCompleta = true;
    });
  }
  /** Para modal **/
  crear() {
    this.openDialogSave(this.dialog);
  }
  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.openEdit(this.dialogEdit, event.data);
        break;
      case "delete":
        this.eliminarCategoria(event);
        break;
    }
  }
  /**
   * Eliminar categoria
   * @param even Datos de categoria a eliminar
   */
  eliminarCategoria(even) {
    Swal.fire({
      title: "¿Seguro que desea eliminar la categoría?",
      text: "Los datos borrados no podrar ser recuperados!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No eliminar!"
    }).then(result => {
      if (result.value) {
        this.cargaCompleta = false;
        this.mainService
          .delete2("api/categorias/" + even.data._id)
          .subscribe(res => {
            this.getCategorias();
            //this.cargaCompleta = true;
            if (res) {
              Swal.fire(
                "Se elimino con exito!",
                "La categoria fue eliminada.",
                "success"
              );
            }
          });
      }
    });
  }
  /** Levanta modal de edicion y carga los datos del objeto */
  openEdit(dialog, data) {
    this.datosEdit.nombre = data.nombre;
    this.datosEdit._id = data._id;
    this.datosEdit.icono = '/assets/admin/sin-imagen.jpg';
    this.datosEdit.refS3 = undefined;
    if (data.icono !== null && data.icono !== undefined ) {
      this.datosEdit.icono = data.icono;
      this.datosEdit.refS3 = data.refS3;
    }
    
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /**
   * Funcion que ejecuta el servicio de crear categoria de proveedores
   */
  ejucutarGuardar() {
    const categoria = this.frmCrear.value;
    if (this.icono !== null ){
      categoria.icono = this.icono;
      categoria.refS3 = this.refS3;
    }
    this.mainService.post2("api/categorias", categoria).subscribe(res => {
      this.spinerGuardar = false;
      if (res.success) {
        this.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se creo con éxito el registro!"
        );
        this.reiniciarFormularioCrear();
        const element: HTMLElement = document.getElementById(
          "btn-close"
        ) as HTMLElement;
        element.click();
      } else {
        this.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
    });
  }
  /**
   * Funcion que inicnia proceso de guardar categoria
   */
  guardar() {
    this.submitted = true;
    if (this.frmCrear.invalid) {
      return false;
    }
    this.spinerGuardar = true;
    this.guardarImagen('crear');
  }
  /**
   * Funcion que ejecuta el servicio de modificar categoria de proveedores
   */
  modificar() {
    this.submitted2 = true;
    if (this.frm.invalid) {
      return false;
    }
    this.spinerEdit = true;
    this.guardarImagen('editar');
  }
  finalizarModificar() {
    const categoria = this.frm.value;
    categoria.refS3 = this.datosEdit.refS3;
    categoria.icono = this.datosEdit.icono;
    this.mainService
      .put2("api/categorias/" + categoria._id, categoria)
      .subscribe(res => {
        if (res.success) {
          this.showToast(
            "top-right",
            "success",
            "Éxito!",
            "Se modifico con éxito el registro!"
          );
          this.reiniciarFormulario();
          const element: HTMLElement = document.getElementById(
            "btn-close-edit"
          ) as HTMLElement;
          element.click();
        } else {
          this.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
        }
        this.spinerEdit = false;
      });
  }
  /** reiniciar formulario de edicion */
  reiniciarFormulario() {
    this.frm.reset();
    this.submitted2 = false;
    this.datosEdit = {
      _id: "",
      nombre: "",
      refS3: '',
      icono: ''
    };
    this.contenidoFileE = undefined;
    this.previewE = null;

    this.getCategorias();
  }
  /** reiniciar formulario de creacion */
  reiniciarFormularioCrear() {
    this.frmCrear.reset();
    this.submitted = false;
    this.getCategorias();
    this.icono = null;
    this.refS3 = '';
    this.preview = null;
    const element: HTMLElement = document.getElementById(
      "fileSelect"
    ) as HTMLElement;
    element.nodeValue = "";
    this.contenidoFile = undefined;
  }
  /**
   * Funcion para abrir el explorador de archivos
   */
  abrirArchivos() {
    const element: HTMLElement = document.getElementById(
      "fileSelect"
    ) as HTMLElement;
    element.click();
  }
  /**
   * Funcion listener para la carga de archivo a importar
   * @param evt archivo a cargar
   */
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer> (evt.target);
    if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.listaImportar = <AOA> (XLSX.utils.sheet_to_json(ws, {header: 1, raw: false, defval:null}));
        this.mapeoDeArchivo();
    };
    reader.readAsBinaryString(target.files[0]);
  }
  /**
   * Funcion para crear estrucutra a importar en la base de datos, y confimacion de importarcion
   */
  mapeoDeArchivo() {
    let datosImportar: CategoriaInterface[] = [];
    for (let item of this.listaImportar) {
      datosImportar.push({nombre: item});
    }
    Swal.fire({
      title: "¿Seguro que desea importar" + datosImportar.length + " categorías?",
      text: "Se importaran las categorías dentro del archivo sin tener en cuenta duplicados!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, importar!",
      cancelButtonText: "No, importar!"
    }).then(result => {
      if (result.value) {
        this.cargaCompleta = false;
        this.mainService
          .post2("api/categorias/importar", datosImportar)
          .subscribe(res => {
            this.cargaCompleta = true;
            this.getCategorias();
            if (res) {
              Swal.fire(
                "Se importo con exito!",
                "Verifica la lista de categorías.",
                "success"
              );
            }
          });
      }
    });
  }
  /** funciones para carga de icono */
  public abrirArchivo () {
    const element: HTMLElement = document.getElementById(
      'img-segundarias'
    ) as HTMLElement
    element.click();
  }
  public inputImagen (event) {
    this.contenidoFile = event.target.files[0];
    let archivos = event.target.files
    for (let archivo of archivos) {
      let reader = new FileReader()
      reader.onload = (img: any) => {
        this.preview = img.target.result;
      }
      reader.readAsDataURL(archivo)
    }
  }
  /** funciones para carga de icono edicion*/
  public abrirArchivoE () {
    const element: HTMLElement = document.getElementById(
      'img-segundariasE'
    ) as HTMLElement
    element.click();
  }
  public inputImagenE (event) {
    this.contenidoFileE = event.target.files[0];
    let archivos = event.target.files
    for (let archivo of archivos) {
      let reader = new FileReader()
      reader.onload = (img: any) => {
        this.previewE = img.target.result;
      }
      reader.readAsDataURL(archivo)
    }
  }
  /**
   * Funcion para crear codigo aleatorio para las referenicas de las imagenes
   * @param length longintud del codigo aleataorio a crear
   */
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Metodo para guardar las imagenes tanto al editar como al crear
   * @param tipo determina si la imagen es para editar o crear la noticia
   * @param pos Posicion dentro del arreglo que almacena las imagenes tanto en el crear como en el editar
   */
  guardarImagen(tipo) {
    let idImagen = "cat-" + this.makeid(8);
    let imagen = this.contenidoFile;
    if (tipo === "editar") {
      if (this.datosEdit.icono !== undefined && this.datosEdit.icono !== null){
        if (this.datosEdit.refS3 !== undefined) {
          idImagen = this.datosEdit.refS3;
        }
      }
      imagen = this.contenidoFileE;
    }
    let self = this;
    if (imagen !== undefined) {
      let file = imagen;
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: idImagen,
        ContentType: file.type,
        Body: file
      };
      let options = {
        partSize: 10 * 1024 * 1024,
        queueSize: 1
      };
      let uploader = this.bucket.upload(params, options, function(err, data) {
        if (err) {
          console.log("PERDIDAS");
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          self.spinerEdit = false;
          self.spinerGuardar = false;
          return false;
        }
        let timestamp = new Date().getTime();
        if (tipo === "crear") {
          
          self.icono = data.Location.toString()+"?t=" + timestamp;
          self.refS3 = idImagen;
          self.ejucutarGuardar();
        } else {
          self.datosEdit.icono = data.Location.toString()+"?t=" + timestamp;
          self.datosEdit.refS3 =  idImagen
          self.finalizarModificar();
        }
      });
      uploader.on("httpUploadProgress", function(event) {
        console.log((event.loaded * 100) / event.total);
      });
    } else {
      if (tipo === "crear") {
        self.ejucutarGuardar();
      } else {
        self.finalizarModificar();
      }
    }
  }
  /** 
   * Metodo que hace el llamado al servico de exportat a xls
   */
  exportarXls(){
    this.exportando = true;
    let datos = {
      listado: this.lista,
      primeraFila: ['Nombre'],
      campos: ['nombre'],
      nombre: 'Lista-de-categorias'
    };
    if (this.utilService.reporteXls(datos)) {
      this.exportando = false;
    }
  }
}
