import { MainService } from './../services/main.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormGroupDirective} from '@angular/forms';
import {NbToastrService, NbDialogService, NbSpinnerService} from '@nebular/theme';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  spinerLogin = false;
  /* Manejo display password */
  private oculto = true;

  /* Manejo de Usuario */
  public user: any = {};
  loading = false;
  /* Indica el estado de carga */
  public cargando: boolean = false;

  /* API Routes */
  readonly API_AUTH: string = "users";

  /* Observables - Async */
  private allObervable: Observable<any>;

  /* Manejo de subscripcion para evitar Memory Leaks */
  // disclamer: HTTP Request manejan su propio unsubscribe por lo que no generan leaks.
  private subscriptions: Subscription[] = [];

  /* Child References */
  @ViewChild("registerForm", { static: false })
  registerForm: FormGroupDirective;

  /* Dialog que se encuentra abierto */
  private dialogActivo: any;

  constructor(
    private authService: AuthService,
    private mainService: MainService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private router: Router,
    private spinnerService: NbSpinnerService
  ) { }

  ngOnInit() {
    if (localStorage.getItem("userYFadmin")) {
      this.routerNavigateTo({ path: "/panel/home" });
    }
  }

  // =================================
  // Metodos
  // ================================

  /**
   * Muestra o oculta la contraseña al usuario
   */
  public showPassword() {
    this.oculto = !this.oculto;
  }

  /**
   * Muestra un mensaje cuando el servidor responde NULL
   */
  private handleResponseNull(): void {
    this.showToastr({
      title: "No se ha establecido una conexión con el servidor.",
      status: "basic",
    });
  }

  /**
   * Muestra un mensaje con el error
   * @param {any} error generado por el request
   */
  private handleError({ error }: { error: any }): void {
    this.showToastr({
      title: "A ocurrido un error",
      status: "warning",
      message: error.message || error || "not found",
    });
  }

  /**
   * Muestra un mensaje de success
   * @param {string} title del mensaje
   */
  private handleSuccess({ title }: { title: string }): void {
    this.showToastr({
      title: `${title}`,
      status: "success",
    });
  }

  // =================================
  // Metodos CRUD
  // ================================

  public onLogin({ value, valid }: { value: any; valid: boolean }): void {
    this.loading = true;

    if (!(value && valid)) {
      this.showToastr({
        title: "Faltan campos por llenar.",
        status: "warning",
      });
      return;
    }

    const usuario: any = {
      email: value.email,
      password: value.password,
    };
    //console.log(usuario)

    /* this.allObervable = this.mainService.post({
      api: `${this.API_AUTH}/authenticate`,
      data: usuario,
    }); */
    this.authService.authenticateUser(usuario).subscribe(
      data => {
        //console.log(data)
        if (data) {
          if (data.errors || !data.success){
            this.handleError({
              error: data.msg || data.message || data,
            });
            this.loading = false;
          }
          else {
            if (data.user) {
              if (data.user.tipo == "administrador") {
                this.spinnerService.load();
                this.loading = false;

                this.authService.storeUserData(data.token, data.user);
                this.routerNavigateTo({ path: "/panel/home" });
                if (this.registerForm) this.registerForm.reset();
              } else {
                this.loading = false;

                this.showToastr({
                  title: "Not authorized!",
                  status: "danger",
                });
              }
            } else this.handleError({ error: "User not found!" });
          }
        } else {
          this.loading = false;
          this.handleResponseNull();
        }
      }, error => {
        this.loading = false;
        console.log(error);
        alert('Ocurrio un error.');
      })
    /*     this.authService.authenticateUser(usuario).subscribe((response) => {
          console.log("response", response);
    
        }); */
  }

  // =================================
  // Metodos Genericos
  // ================================

  /**
   * Muestra una notificacion estilo Toastr en la pantalla actual
   * @param  {string} position determina en que parte de la pantalla se representará el Toastr
   * @param  {any} title titulo del Toastr
   * @param  {any} message contenido del Toastr
   * @param  {any} status puede ser (basic, primary, success, info, warning, danger, control)
   * @returns void
   */
  private showToastr({
    position,
    title,
    message,
    status,
  }: {
    position?: any;
    title: string;
    message?: string;
    status: any;
  }): void {
    let realPosition = position ? position : "top-end";
    let realMessage = message ? message : "";
    let duractionMilisec = 4500;
    this.toastrService.show(`${realMessage}`, `${title}`, {
      position: realPosition,
      status,
      duration: duractionMilisec,
    });
  }

  /**
   * Se encarga de navegar a la URL especificada
   * @param {string} path -> "/dashboard/inventario/:id"
   * @return void
   */
  public routerNavigateTo({ path }: { path: string }): void {
    this.router.navigate([path]).then((fulfilled) => {
      if (!fulfilled) {
        this.showToastr({
          title: "Dirección URL no válida.",
          status: "basic",
        });
      }
    });
  }

  /**
   * Abre un dialogo estilo nebular
   * @param  {any} dialog referencia al template en html
   * que contiene el dialogo. Debería der tipye ElementRef o TypeReference
   * @returns void
   */
  public showDialog({ dialog }: { dialog: any }): void {
    this.dialogActivo = this.dialogService.open(dialog, { context: "" });
  }
}
