import { UtilService } from './../services/util.service';
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";

import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";

import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import * as moment from "moment";
import * as _ from "lodash";
import { AngularEditorConfig } from "@kolkov/angular-editor";
@Component({
  selector: "app-horoscopo",
  templateUrl: "./horoscopo.component.html",
  styleUrls: ["./horoscopo.component.scss"]
})
export class HoroscopoComponent implements OnInit {
  cargaCompleta = false;
  /*  Para el modal */
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  frm: FormGroup;
  submitted2 = false;
  spinerEdit = false;
  /*****/
  settings = {
    hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [{ name: "edit", title: '<i class="nb-compose"></i> ' }]
    },
    columns: {
      nombre: { title: "Signo" },
      descripcion: {
        title: "Descripción",
        type: "html",
        valuePrepareFunction: cont => {
          return cont;
        }
      },
      rango: { title: "Rango" },
      numeroSuerte: { title: "Número de la suerte" },
      icono: {
        title: "Icono",
        type: "html",
        valuePrepareFunction: img => {
          return '<img src="' + img + '" class="foto-lista" />';
        }
      },
      icono2: {
        title: "Icono detalle",
        type: "html",
        valuePrepareFunction: img => {
          return '<img src="' + img + '" class="foto-lista" />';
        }
      }
    }
  };

  lista = [];
  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1"
  });
  contenidoFileE: any = undefined;
  contenidoFileE2: any = undefined;
  preview: any = null;
  preview2: any = null;
  datosEdit = {
    _id: "",
    nombre: "",
    descripcion: "",
    rango: "",
    numeroSuerte: "",
    icono: "",
    icono2: ""
  };
  /** Cargando del exportar */
  exportando= false;
  public source: LocalDataSource;
  /** Configuracion del editor de contenidos */
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Escribe aquí...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" }
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ],
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      [],
      ["fontSize", "insertImage", "insertVideo", "removeFormat"]
    ]
  };
  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    private rutaActiva: ActivatedRoute,
    public utilService: UtilService
  ) {
    this.frm = this.fb.group({
      nombre: ["", [Validators.required]],
      descripcion: ["", [Validators.required]],
      rango: ["", [Validators.required]],
      numeroSuerte: ["", [Validators.required]],
      icono: ["", []],
      icono2: ["", []],
      _id: ["", []]
    });
  }

  ngOnInit() {
    this.getHoroscopo();
  }
  get f2() {
    return this.frm.controls;
  }
  getHoroscopo() {
    this.mainService.get({ api: "api/horoscopo" }).subscribe(data => {
      for (let d of data) {
        if (d.icono === undefined || d.icono === null || d.icono === "") {
          d.icono = "/assets/admin/sinfoto.png";
        }
        if (d.icono2 === undefined || d.icono2 === null || d.icono2 === "") {
          d.icono2 = "/assets/admin/sinfoto.png";
        }
      }
      this.lista = data;
      this.source = new LocalDataSource(this.lista);
      this.cargaCompleta = true;
    });
  }
  /** Para modal **/

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.openEdit(this.dialogEdit, event.data);
        break;
    }
  }
  openEdit(dialog, data) {
    this.datosEdit.nombre = data.nombre;
    this.datosEdit.descripcion = data.descripcion;
    this.datosEdit.rango = data.rango;
    this.datosEdit.numeroSuerte = data.numeroSuerte;
    this.datosEdit._id = data._id;

    this.datosEdit.icono = "";
    if (data.icono !== undefined && data.icono !== null) {
      this.datosEdit.icono = data.icono;
    }
    this.datosEdit.icono2 = "";
    if (data.icono2 !== undefined && data.icono2 !== null) {
      this.datosEdit.icono2 = data.icono2;
    }
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }

  modificar() {
    this.submitted2 = true;
    const horoscopo = this.frm.value;
    if (this.frm.invalid) {
      return false;
    }
    this.spinerEdit = true;

    let self = this;
    if (this.contenidoFileE !== undefined) {
      let file = this.contenidoFileE;
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: "horoscopo-" + horoscopo._id,
        ContentType: file.type,
        Body: file
      };
      let options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1
        // Give the owner of the bucket full control
      };

      let uploader = this.bucket.upload(params, options, function(err, data) {
        if (err) {
          console.log("PERDIDAS");
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          return false;
        }
        console.log("SUCCESS");
        let timestamp = new Date().getTime();
        horoscopo.icono = data.Location.toString()+"?t=" + timestamp;
        if (self.contenidoFileE2 !== undefined) {
          self.cargarIcono({ horoscopo: horoscopo });
        } else {
          horoscopo.icono2 = self.datosEdit.icono2;
          self.mainService
            .put2("api/horoscopo/" + horoscopo._id, horoscopo)
            .subscribe(res => {
              if (res.success) {
                self.showToast(
                  "top-right",
                  "success",
                  "Éxito!",
                  "Se modifico con éxito el signo!"
                );
                self.reiniciarFormulario();
                const element: HTMLElement = document.getElementById(
                  "btn-close-edit"
                ) as HTMLElement;
                element.click();
              } else {
                self.showToast(
                  "top-right",
                  "warning",
                  "Érror!",
                  "Ocurrio un error por favor intenta de nuevo!"
                );
              }
              self.spinerEdit = false;
            });
        }
      });
      uploader.on("httpUploadProgress", function(event) {
        console.log((event.loaded * 100) / event.total);
      });
    } else {
      horoscopo.icono = this.datosEdit.icono;
      if (self.contenidoFileE2 !== undefined) {
        self.cargarIcono({ horoscopo: horoscopo });
      } else {
        horoscopo.icono2 = self.datosEdit.icono2;
        this.mainService
          .put2("api/horoscopo/" + horoscopo._id, horoscopo)
          .subscribe(res => {
            if (res.success) {
              self.showToast(
                "top-right",
                "success",
                "Éxito!",
                "Se modifico con éxito el registro!"
              );
              self.reiniciarFormulario();
              const element: HTMLElement = document.getElementById(
                "btn-close-edit"
              ) as HTMLElement;
              element.click();
            } else {
              self.showToast(
                "top-right",
                "warning",
                "Érror!",
                "Ocurrio un error por favor intenta de nuevo!"
              );
            }
            self.spinerEdit = false;
          });
      }
    }
  }

  cargarIcono({ horoscopo }) {
    let self = this;
    let file = this.contenidoFileE2;
    let params = {
      Bucket: "yoferretero-recursos",
      ACL: "public-read",
      Key: "h2-" + horoscopo._id,
      ContentType: file.type,
      Body: file
    };
    let options = {
      // Part Size of 10mb
      partSize: 10 * 1024 * 1024,
      queueSize: 1
      // Give the owner of the bucket full control
    };

    let uploader = this.bucket.upload(params, options, function(err, data) {
      if (err) {
        console.log("PERDIDAS");
        self.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
        return false;
      }
      console.log("SUCCESS");
      let timestamp = new Date().getTime();
      horoscopo.icono2 = data.Location.toString()+"?t=" + timestamp;
      self.mainService
        .put2("api/horoscopo/" + horoscopo._id, horoscopo)
        .subscribe(res => {
          if (res.success) {
            self.showToast(
              "top-right",
              "success",
              "Éxito!",
              "Se modifico con éxito el signo!"
            );
            self.reiniciarFormulario();
            const element: HTMLElement = document.getElementById(
              "btn-close-edit"
            ) as HTMLElement;
            element.click();
          } else {
            self.showToast(
              "top-right",
              "warning",
              "Érror!",
              "Ocurrio un error por favor intenta de nuevo!"
            );
          }
          self.spinerEdit = false;
        });
    });
    uploader.on("httpUploadProgress", function(event) {
      console.log((event.loaded * 100) / event.total);
    });
  }

  reiniciarFormulario() {
    this.frm.reset();
    this.submitted2 = false;
    this.datosEdit = {
      _id: "",
      nombre: "",
      descripcion: "",
      rango: "",
      numeroSuerte: "",
      icono: "",
      icono2: ""
    };
    this.getHoroscopo();
    this.contenidoFileE = undefined;
    this.contenidoFileE2 = undefined;
  }

  /** funciones para carga de icono */
  public abrirArchivo () {
    const element: HTMLElement = document.getElementById(
      'icono'
    ) as HTMLElement
    element.click();
  }
  public inputImagen (event) {
    this.contenidoFileE = event.target.files[0];
    let archivos = event.target.files
    for (let archivo of archivos) {
      let reader = new FileReader()
      reader.onload = (img: any) => {
        this.preview = img.target.result;
      }
      reader.readAsDataURL(archivo)
    }
  }
  /** funciones para carga de icono edicion*/
  public abrirArchivoE () {
    const element: HTMLElement = document.getElementById(
      'icono-interno'
    ) as HTMLElement
    element.click();
  }
  public inputImagenE (event) {
    this.contenidoFileE2 = event.target.files[0];
    let archivos = event.target.files
    for (let archivo of archivos) {
      let reader = new FileReader()
      reader.onload = (img: any) => {
        this.preview2 = img.target.result;
      }
      reader.readAsDataURL(archivo)
    }
  }

  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /** 
   * Metodo que hace el llamado al servico de exportat a xls
   */
  exportarXls(){
    this.exportando = true;
    let datos = {
      listado: this.lista,
      primeraFila: ['Nombre', 'Descripción', 'Rango', 'Número'],
      campos: ['nombre', 'descripcion', 'rango', 'numeroSuerte'],
      nombre: 'Lista-de-horoscopo'
    };
    if (this.utilService.reporteXls(datos)) {
      this.exportando = false;
    }
  }
}
