import { UtilService } from './../services/util.service';
import { PreguntaInterface } from "./../../model/pregunta";
import { MainService } from "./../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
type AOA = any[][];

@Component({
  selector: "app-preguntas",
  templateUrl: "./preguntas.component.html",
  styleUrls: ["./preguntas.component.scss"]
})
export class PreguntasComponent implements OnInit {
  /** Cargando del exportar */
  exportando= false;
  /** Variable de referencia al modal de importar archivo */
  @ViewChild("dialogImportar", { static: true }) dialogImportar: ElementRef;
  /** Bandera de control para la carga inicial */
  cargaCompleta = false;
  /*  Para el modal variables de referencia a los modal de los formularios */
  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /** Formularios de crear y editar preguntas */
  frm: FormGroup;
  frmCrear: FormGroup;
  /** Variables de control de envio de informacion en los formularios */
  submitted2 = false;
  spinerEdit = false;
  submitted = false;
  spinerGuardar = false;
  /*** Configuracion de la informacion a mostrar en la tabla de preguntas **/
  settings = {
    //hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' }
      ]
    },
    columns: {
      pregunta: { title: "Pregunta", filter: true },
      opciones: {
        filter: false,
        title: "Opciones",
        type: "html",
        valuePrepareFunction: opts => {
          let html = "";
          let i = 0;
          let letras = ['a', 'b', 'c', 'd']
          for (let opt of opts) {
            html += letras[i] + ")" + opt.respuesta + "<br>";
            i++;
          }
          return html;
        }
      },
      respuestaCorrecta: { title: "Respuesta",filter: false,valuePrepareFunction: respuesta => {
        let html = "";
        switch(respuesta) {
          case 0: html = "a";break;
          case 1: html = "b";break;
          case 2: html = "c";break;
          case 3: html = "d";break;
        }
        return html;
      } },
      proveedor: { title: "Proveedor",filter: false },
      nivel: { title: "Nivel",filter: true },
      caducidad: { title: "Caducidad",filter: false }
    }
  };
  /** Lista de preguntas registradas */
  lista = [];
  /** objeto de opciones */
  opciones = [
    { respuesta: "", correcta: false },
    { respuesta: "", correcta: false },
    { respuesta: "", correcta: false },
    { respuesta: "", correcta: false }
  ];
  marcada: number = -1;
  marcadaEdit: number = -1;
  /** Arreglo de letras para las opciones */
  letras = ["a", "b", "c", "d"];
  /** Objeto de control para los datos de edicion */
  datosEdit: PreguntaInterface = {
    _id: "",
    pregunta: "",
    opciones: [],
    proveedor: "",
    nivel: 0,
    caducidad: "",
    respuestaCorrecta: -1
  };
  /** Source a asignar la lista de preguntas y mostrar en la tabla */
  public source: LocalDataSource;
   /** Datos a importar */
   listaImportar: any[] = [];
   /** Nivel de preguntas a importar */
   nivelImportar= -1;
  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    public utilService: UtilService,
    private rutaActiva: ActivatedRoute
  ) {
    this.frm = this.fb.group({
      pregunta: ["", [Validators.required]],
      proveedor: ["", [Validators.required]],
      nivel: ["", [Validators.required]],
      _id: ["", []]
    });
    this.frmCrear = this.fb.group({
      pregunta: ["", [Validators.required]],
      proveedor: ["", [Validators.required]],
      nivel: ["", [Validators.required]],
      caducidad: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    this.getPreguntas();
  }
  /*** Funcionea para abreviar la forma de obtener los datos de los formularios */
  get f2() {
    return this.frm.controls;
  }
  get f() {
    return this.frmCrear.controls;
  }
  /**
   * Funcion para obtener listado de preguntas desde el servicio
   */
  getPreguntas() {
    this.mainService.get({ api: "api/pregunta" }).subscribe(data => {
      if (data.success){
        this.lista = data.data;
      } else {
        this.lista = [];
      }
      this.source = new LocalDataSource(this.lista);
      this.cargaCompleta = true;
    });
  }
  /** Para modal **/
  crear() {
    this.openDialogSave(this.dialog);
  }
  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.openEdit(this.dialogEdit, event.data);
        break;
      case "delete":
        this.eliminarPregunta(event);
        break;
    }
  }
  /**
   * Eliminar categoria
   * @param even Datos de categoria a eliminar
   */
  eliminarPregunta(even) {
    Swal.fire({
      title: "¿Seguro que desea eliminar la pregunta?",
      text: "Los datos borrados no podrar ser recuperados!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No eliminar!"
    }).then(result => {
      if (result.value) {
        this.cargaCompleta = false;
        this.mainService
          .delete2("api/pregunta/" + even.data._id)
          .subscribe(res => {
            this.getPreguntas();
            //this.cargaCompleta = true;
            if (res) {
              Swal.fire(
                "Se elimino con exito!",
                "La pregunta fue eliminada.",
                "success"
              );
            }
          });
      }
    });
  }
  /** Levanta modal de edicion y carga los datos del objeto */
  openEdit(dialog, data) {
    this.datosEdit.pregunta = data.pregunta;
    this.datosEdit._id = data._id;
    this.datosEdit.proveedor = data.proveedor;
    this.datosEdit.nivel = data.nivel;
    this.datosEdit.opciones = data.opciones;
    let fecha = new Date(data.caducidad);
    this.datosEdit.caducidad = fecha;
    this.marcadaEdit = data.respuestaCorrecta;
    
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog"
    });
  }
  /**
   * Funcion que ejecuta el servicio de crear pregunta de los datos del formulario de creación
   */
  guardar() {
    this.submitted = true;
    if (this.frmCrear.invalid) {
      return false;
    }
    if (this.marcada ===  -1) {
      this.showToast(
        "top-right",
        "warning",
        "Alerta!",
        "Debe seleccionar la respuesta correcta!"
      );
      return false;
    }
    this.spinerGuardar = true;
    const pregunta = this.frmCrear.value;
    pregunta.opciones = this.opciones;
    pregunta.createdAt = new Date();
    pregunta.respuestaCorrecta = this.marcada;
    let caducidad = new Date(pregunta.caducidad);
    pregunta.caducidad = caducidad;
    this.mainService.post2("api/pregunta", pregunta).subscribe(res => {
      this.spinerGuardar = false;
      if (res.success) {
        this.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se creo con éxito el registro!"
        );
        this.reiniciarFormularioCrear();
        const element: HTMLElement = document.getElementById(
          "btn-close"
        ) as HTMLElement;
        element.click();
      } else {
        this.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
    });
  }
  /**
   * Funcion que ejecuta el servicio de modificar pregunta
   */
  modificar() {
    this.submitted2 = true;
    if (this.frm.invalid) {
      return false;
    }
    if (this.marcadaEdit ===  -1) {
      this.showToast(
        "top-right",
        "warning",
        "Alerta!",
        "Debe seleccionar la respuesta correcta!"
      );
      return false;
    }
    this.spinerEdit = true;
    const pregunta = this.frm.value;
    pregunta.opciones = this.datosEdit.opciones;
    pregunta.respuestaCorrecta = this.marcadaEdit;
    let caducidad = new Date(this.datosEdit.caducidad);
    pregunta.caducidad = caducidad;
    this.mainService
      .put2("api/pregunta/" + pregunta._id, pregunta)
      .subscribe(res => {
        if (res.success) {
          this.showToast(
            "top-right",
            "success",
            "Éxito!",
            "Se modifico con éxito el registro!"
          );
          this.reiniciarFormulario();
          const element: HTMLElement = document.getElementById(
            "btn-close-edit"
          ) as HTMLElement;
          element.click();
        } else {
          this.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
        }
        this.spinerEdit = false;
      });
  }
  /** reiniciar formulario de edicion */
  reiniciarFormulario() {
    this.frm.reset();
    this.submitted2 = false;
    this.datosEdit = {
      _id: "",
      pregunta: "",
      opciones: [],
      proveedor: "",
      nivel: 0,
      caducidad: "",
      respuestaCorrecta: -1
    };
    this.getPreguntas();
    this.marcadaEdit = -1;
  }
  /** reiniciar formulario de creacion */
  reiniciarFormularioCrear() {
    this.frmCrear.reset();
    this.submitted = false;
    this.getPreguntas();
    this.opciones = [
      { respuesta: "", correcta: false },
      { respuesta: "", correcta: false },
      { respuesta: "", correcta: false },
      { respuesta: "", correcta: false }
    ];
    this.marcada = -1;
  }
  /**
   * Marcar correcta la pregunta seleccionada
   * @param param0 Posicion del la pregunta correcat del arreglo de opciones
   */
  marcarCorrecta({pos}) {
    for ( let item of this.opciones) {
      item.correcta = false;
    }
    this.opciones[pos].correcta = true;
    this.marcada = pos;
  }
  /**
   * Desmarcar pregunta correcta
   * @param param0 Posicion de la pregunta a desmarcar
   */
  marcarInCorrecta({pos}) {
    this.opciones[pos].correcta = false;
    this.marcada = -1;
  }
  /**
   * Marcar correcta la pregunta seleccionada  formulario de edicion
   * @param param0 Posicion del la pregunta correcat del arreglo de opciones
   */
  marcarCorrectaEdit({pos}) {
    for ( let item of this.datosEdit.opciones) {
      item.correcta = false;
    }
    this.datosEdit.opciones[pos].correcta = true;
    this.marcadaEdit = pos;
  }
  /**
   * Desmarcar pregunta correcta formulario de edicion
   * @param param0 Posicion de la pregunta a desmarcar
   */
  marcarInCorrectaEdit({pos}) {
    this.datosEdit.opciones[pos].correcta = false;
    this.marcadaEdit = -1;
  }

  /** Metodos para importar preguntas desde archivo excel con el formato pre-establecido */
  /**
   * Abrir el modal para importar archivo
   */
  modalImportar() {
    this.openDialogSave(this.dialogImportar);
  }
  /**
   * Funcion para abrir el explorador de archivos
   */
  abrirImportar() {
    const element: HTMLElement = document.getElementById(
      "fileSelect"
    ) as HTMLElement;
    element.click();
  }
  /**
   * Funcion listener para la carga de archivo a importar
   * @param evt archivo a cargar
   */
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer> (evt.target);
    if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.listaImportar = <AOA> (XLSX.utils.sheet_to_json(ws, {header: 1, raw: false, defval:null}));
    };
    reader.readAsBinaryString(target.files[0]);
  }
  /**
   * Inicia proceso de importar los datos
   */
  ejecutarImportar() {
    let datosImportar: PreguntaInterface[] = [];
    this.listaImportar.splice(0,1);
    let cadu = new Date(2021,8,15);
    for (let item of this.listaImportar) {
      if (item[0] !== null){
        let opciones = [];
      let opt1 = {respuesta: item[1], correcta: false};
      let opt2 = {respuesta: item[2], correcta: false};
      let opt3 = {respuesta: item[3], correcta: false};
      let opt4 = {respuesta: item[4], correcta: false};
      opciones.push(opt1);
      opciones.push(opt2);
      opciones.push(opt3);
      opciones.push(opt4);
      let AuxCorrecta = item[5].split(' ');
      let correcta = AuxCorrecta[1] - 1;
      opciones[correcta].correcta = true;
      datosImportar.push({pregunta: item[0], opciones: opciones, respuestaCorrecta: correcta, nivel: this.nivelImportar, proveedor: 'YoFerretero', caducidad: cadu });
      }
    }
    this.listaImportar = datosImportar;
    //console.log(this.listaImportar);
    Swal.fire({
      title: "¿Seguro que desea importar " + this.listaImportar.length + " preguntas?",
      text: "Se importaran las preguntas dentro del archivo sin tener en cuenta duplicados!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, importar!",
      cancelButtonText: "No, importar!"
    }).then(result => {
      if (result.value) {
        this.cargaCompleta = false;
        this.mainService
          .post2("api/pregunta/importar", this.listaImportar)
          .subscribe(res => {
            this.cargaCompleta = true;
            this.getPreguntas();
            this.listaImportar = [];
            this.nivelImportar = -1;
            const element: HTMLElement = document.getElementById(
              'btn-close-import'
            ) as HTMLElement
            element.click();
            if (res) {
              Swal.fire(
                "Se importo con exito!",
                "Verifica la lista de preguntas.",
                "success"
              );
            }
          });
      }
    });
  }
  /** 
   * Metodo que hace el llamado al servico de exportat a xls
   */
  exportarXls(){
    this.exportando = true;
    let datos = {
      listado: this.lista,
      primeraFila: ['Pregunta', 'Opciones', 'Proveedor', 'Nivel', 'Respuesta'],
      campos: ['pregunta', 'opciones', 'proveedor', 'nivel', 'respuestaCorrecta'],
      nombre: 'Lista-de-preguntas'
    };
    if (this.utilService.reporteXls(datos)) {
      this.exportando = false;
    }
  }
}
