import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-georefencia',
  templateUrl: './georefencia.component.html',
  styleUrls: ['./georefencia.component.scss']
})
export class GeorefenciaComponent implements OnInit {
  lat = 19.290950;
   lng = -99.653015;
   zoom = 9;
  constructor() { }

  ngOnInit() {
  }

}
