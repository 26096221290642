import { UtilService } from "../services/util.service";
import { ExpositoresInterface } from "../../model/expositores";
import { MainService } from "../services/main.service";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import Swal from "sweetalert2";
type AOA = any[][];
import * as S3 from "aws-sdk/clients/s3";

@Component({
  selector: "app-gran-salon-ferretero",
  templateUrl: "./gran-salon-ferretero.component.html",
  styleUrls: ["./gran-salon-ferretero.component.scss"],
})
export class GranSalonFerreteroComponent implements OnInit {
  bucket: S3 = new S3({
    accessKeyId: "AKIAUT7IXVID77RSYJ5F",
    secretAccessKey: "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",
    region: "us-east-1",
  });
  /** Bandera de control para la carga inicial */
  cargaCompleta = false;
  /*  Para el modal variables de referencia a los modal de los formularios */
  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  @ViewChild("dialogEdit", { static: true }) dialogEdit: ElementRef;
  /** Formularios de crear y editar Expositores */
  frm: FormGroup;
  frmCrear: FormGroup;
  /** Variables de control de envio de informacion en los formularios */
  submitted2 = false;
  spinerEdit = false;
  submitted = false;
  spinerGuardar = false;
  /*** Configuracion de la informacion a mostrar en la tabla de Expositores **/
  settings = {
    //hideSubHeader: false,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: true,
      custom: [
        { name: "edit", title: '<i class="nb-compose"></i> ' },
        { name: "delete", title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nombre: { title: "Nombre", filter: true },
      stand: { title: "Stand", filter: true },
      telefono: { title: "Whatsapp", filter: true },
      email: { title: "Email", filter: true },
      logo: {
        title: "Logo",
        filter: false,
        type: "html",
        valuePrepareFunction: (img) => {
          if (img !== null && img !== undefined && img !== "") {
            return '<img src="' + img + '" class="foto-lista" />';
          }
          return '<img src="/assets/admin/sin-imagen.jpg" class="foto-lista" />';
        },
      },
    },
  };
  /** Lista de expositores registradas */
  lista = [];
  /** Objeto de control para los datos de edicion */
  datosEdit: ExpositoresInterface = {
    _id: "",
    nombre: "",
    stand: "",
    telefono: "",
    email: "",
    logo: ""
  };
  /** Source a asignar la lista de categorias y mostrar en la tabla */
  public source: LocalDataSource;
  /** Imagen del icono */
  logo: any = null;
  refS3: string = "";
  preview: any = null;
  previewE: any = null;
  /** data de imagen */
  contenidoFile: any;
  contenidoFileE: any;
  /** Cargando exportar */
  exportando = false;
  constructor(
    public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public mainService: MainService,
    private rutaActiva: ActivatedRoute,
    public utilService: UtilService
  ) {
    this.frm = this.fb.group({
      nombre: ["", [Validators.required]],
      stand: ["", [Validators.required]],
      telefono: ["", []],
      email: ["", []],
      _id: ["", []],
    });
    this.frmCrear = this.fb.group({
      nombre: ["", [Validators.required]],
      stand: ["", [Validators.required]],
      telefono: ["", []],
      email: ["", []],
    });
  }

  ngOnInit() {
    this.getExpositores()
  }
  /*** Funcionea para abreviar la forma de obtener los datos de los formularios */
  get f2() {
    return this.frm.controls;
  }
  get f() {
    return this.frmCrear.controls;
  }
  /**
   * Funcion para obtener listado de proveedores
   */
  getExpositores() {
    this.mainService.get({ api: "api/expositores" }).subscribe((data) => {
      // console.log(data);
      this.lista = data;
      this.source = new LocalDataSource(this.lista);
      this.cargaCompleta = true;
    });
  }
  /** Para modal **/
  crear() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }
  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
  /** Acciones de los botones de las filas de smart-table */
  onCustom(event) {
    switch (event.action) {
      case "edit":
        this.openEdit(this.dialogEdit, event.data);
        break;
      case "delete":
        this.eliminarExpositor(event);
        break;
    }
  }
  /**
   * Eliminar categoria
   * @param even Datos de categoria a eliminar
   */
  eliminarExpositor(even) {
    Swal.fire({
      title: "¿Seguro que desea eliminar el expositor?",
      text: "Los datos borrados no podrar ser recuperados!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No eliminar!",
    }).then((result) => {
      if (result.value) {
        this.cargaCompleta = false;
        this.mainService
          .delete2("api/expositores/" + even.data._id)
          .subscribe((res) => {
            this.getExpositores();
            //this.cargaCompleta = true;
            if (res) {
              Swal.fire(
                "Se elimino con exito!",
                "El expositor fue eliminado.",
                "success"
              );
            }
          });
      }
    });
  }
  /** Levanta modal de edicion y carga los datos del objeto */
  openEdit(dialog, data) {
    this.datosEdit.nombre = data.nombre;
    this.datosEdit.email = data.email;
    this.datosEdit.stand = data.stand;
    this.datosEdit.telefono = data.telefono;
    this.datosEdit._id = data._id;
    this.datosEdit.logo = "/assets/admin/sin-foto.jpg";
    this.datosEdit.refS3 = undefined;
    if (data.logo !== null && data.logo !== undefined) {
      this.datosEdit.logo = data.logo;
      this.datosEdit.refS3 = data.refS3;
    }
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }
  /**
   * Funcion que ejecuta el servicio de crear categoria de proveedores
   */
  ejucutarGuardar() {
    const expositor = this.frmCrear.value;
    if (this.logo !== null) {
      expositor.logo = this.logo;
      expositor.refS3 = this.refS3;
    }
    
    this.mainService.post2("api/expositores", expositor).subscribe((res) => {
      this.spinerGuardar = false;
      if (res.success) {
        this.showToast(
          "top-right",
          "success",
          "Éxito!",
          "Se creo con éxito el registro!"
        );
        this.reiniciarFormularioCrear();
        const element: HTMLElement = document.getElementById(
          "btn-close"
        ) as HTMLElement;
        element.click();
      } else {
        this.showToast(
          "top-right",
          "warning",
          "Érror!",
          "Ocurrio un error por favor intenta de nuevo!"
        );
      }
    });
  }
  /**
   * Funcion que inicia proceso de guardar expositor
   */
  guardar() {
    this.submitted = true;
    if (this.frmCrear.invalid) {
      return false;
    }
    this.spinerGuardar = true;
    this.guardarImagen("crear");
  }
  /**
   * Funcion que ejecuta el servicio de modificar categoria de expositores
   */
  modificar() {
    this.submitted2 = true;
    if (this.frm.invalid) {
      return false;
    }
    this.spinerEdit = true;
    this.guardarImagen("editar");
  }
  finalizarModificar() {
    const expositor = this.frm.value;
    expositor.refS3 = this.datosEdit.refS3;
    expositor.logo = this.datosEdit.logo;
    this.mainService
      .put2("api/expositores/" + this.datosEdit._id, expositor)
      .subscribe((res) => {
        if (res.success) {
          this.showToast(
            "top-right",
            "success",
            "Éxito!",
            "Se modifico con éxito el registro!"
          );
          this.reiniciarFormulario();
          const element: HTMLElement = document.getElementById(
            "btn-close-edit"
          ) as HTMLElement;
          element.click();
        } else {
          this.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
        }
        this.spinerEdit = false;
      });
  }
  /** reiniciar formulario de edicion */
  reiniciarFormulario() {
    this.frm.reset();
    this.submitted2 = false;
    this.datosEdit = {
      _id: "",
      nombre: "",
      stand: "",
      email: "",
      telefono: "",
      logo: ""
    };
    this.contenidoFileE = undefined;
    this.previewE = null;

    this.getExpositores();
  }
  /** reiniciar formulario de creacion */
  reiniciarFormularioCrear() {
    this.frmCrear.reset();
    this.submitted = false;
    this.getExpositores();
    this.logo = null;
    this.refS3 = "";
    this.preview = null;
    const element: HTMLElement = document.getElementById(
      "fileSelect"
    ) as HTMLElement;
    element.nodeValue = "";
    this.contenidoFile = undefined;
  }
  /**
   * Funcion listener para la carga de archivo a importar
   * @param evt archivo a cargar
   */
  
  /** funciones para carga de logo */
  public abrirArchivo() {
    const element: HTMLElement = document.getElementById(
      "img-segundarias"
    ) as HTMLElement;
    element.click();
  }
  public inputImagen(event) {
    this.contenidoFile = event.target.files[0];
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.preview = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
  /** funciones para carga de icono edicion*/
  public abrirArchivoE() {
    const element: HTMLElement = document.getElementById(
      "img-segundariasE"
    ) as HTMLElement;
    element.click();
  }
  public inputImagenE(event) {
    this.contenidoFileE = event.target.files[0];
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.previewE = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
  /**
   * Funcion para crear codigo aleatorio para las referenicas de las imagenes
   * @param length longintud del codigo aleataorio a crear
   */
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Metodo para guardar las imagenes tanto al editar como al crear
   * @param tipo determina si la imagen es para editar o crear la noticia
   * @param pos Posicion dentro del arreglo que almacena las imagenes tanto en el crear como en el editar
   */
  guardarImagen(tipo) {
    let idImagen = "prov-" + this.makeid(8);
    let imagen = this.contenidoFile;
    if (tipo === "editar") {
      if (this.datosEdit.logo !== undefined && this.datosEdit.logo !== null) {
        if (this.datosEdit.refS3 !== undefined) {
          idImagen = this.datosEdit.refS3;
        }
      }
      imagen = this.contenidoFileE;
    }
    let self = this;
    if (imagen !== undefined) {
      let file = imagen;
      let params = {
        Bucket: "yoferretero-recursos",
        ACL: "public-read",
        Key: idImagen,
        ContentType: file.type,
        Body: file,
      };
      let options = {
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
      };
      let uploader = this.bucket.upload(params, options, function (err, data) {
        if (err) {
          self.showToast(
            "top-right",
            "warning",
            "Érror!",
            "Ocurrio un error por favor intenta de nuevo!"
          );
          self.spinerEdit = false;
          self.spinerGuardar = false;
          return false;
        }
        let timestamp = new Date().getTime();
        if (tipo === "crear") {
          self.logo = data.Location.toString() + "?t=" + timestamp;
          self.refS3 = idImagen;
          self.ejucutarGuardar();
        } else {
          self.datosEdit.logo = data.Location.toString() + "?t=" + timestamp;
          self.datosEdit.refS3 = idImagen;
          self.finalizarModificar();
        }
      });
      uploader.on("httpUploadProgress", function (event) {
      });
    } else {
      if (tipo === "crear") {
        self.ejucutarGuardar();
      } else {
        self.finalizarModificar();
      }
    }
  }
}
