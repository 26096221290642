import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {
  NbThemeModule,
  NbLayoutModule,
  NbCardModule,
  NbSidebarModule,
  NbSidebarService,
  NbMenuModule,
  NbMenuService,
  NbActionsModule,
  NbInputModule,
  NbButtonModule,
  NbCalendarModule,
  NbDatepickerModule,
  NbPopoverModule,
  NbToastrModule,
  NbDialogModule,
  NbSpinnerModule,
  NbUserModule,
  NbSelectModule,
  NbTabsetModule,
  NbCalendarRangeModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ChartModule } from 'angular2-chartjs';
import {NbMomentDateModule} from '@nebular/moment';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {NgbAlertModule, NgbModule, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

import { HomeComponent } from './public/home/home.component';
import { TransaccionesComponent } from './public/transacciones/transacciones.component';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { LoginComponent } from './public/login/login.component';
import { UsuariosComponent } from './public/usuarios/usuarios.component';
import { NoticiasComponent } from './public/noticias/noticias.component';
import { HoroscopoComponent } from './public/horoscopo/horoscopo.component';
import { OfertasComponent } from './public/ofertas/ofertas.component';
import { CategoriasComponent } from './public/categorias/categorias.component';
import { ProveedoresComponent } from './public/proveedores/proveedores.component';
import { BannerComponent } from './public/banner/banner.component';
import { SolonumeroDirective } from './directivas/solonumero.directive';
import { PreguntasComponent } from './public/preguntas/preguntas.component';
import { PremioComponent } from './public/premio/premio.component';
import { GeorefenciaComponent } from './public/georefencia/georefencia.component';
import { AgmCoreModule } from '@agm/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { YoaprendoComponent } from './public/yoaprendo/yoaprendo.component';
import { GranSalonFerreteroComponent } from './public/gran-salon-ferretero/gran-salon-ferretero.component';
import { ConfigSalonComponent } from './public/config-salon/config-salon.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TransaccionesComponent,
    DashboardComponent,
    LoginComponent,
    UsuariosComponent,
    NoticiasComponent,
    HoroscopoComponent,
    OfertasComponent,
    CategoriasComponent,
    ProveedoresComponent,
    BannerComponent,
    SolonumeroDirective,
    PreguntasComponent,
    PremioComponent,
    GeorefenciaComponent,
    YoaprendoComponent,
    GranSalonFerreteroComponent,
    ConfigSalonComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbSidebarModule,
    NbActionsModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbSelectModule,
    NbTabsetModule,
    NbCalendarModule,
    Ng2SmartTableModule,
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    ReactiveFormsModule,
    NbToastrModule.forRoot(),
    NbDialogModule.forChild(),
    NbSpinnerModule,
    NbCalendarRangeModule,
    NbUserModule,
    ChartModule,
    NbMomentDateModule,
    NbDateFnsDateModule.forRoot({ format: 'dd.MM.yyyy' }),
    NbDateFnsDateModule.forChild({ format: 'dd.MM.yyyy' }),
    NbDatepickerModule.forRoot(),
    HttpModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      //apiKey: 'AIzaSyC3mzeH9Bt_NHMheGpwZhIwnb82dzdFrBw'
      apiKey: 'AIzaSyABCOOhZDn9BOu-ugrbr0uhMPeoW0DeC6Q'
    }),
    AngularEditorModule,
    NgbModule,
    AutocompleteLibModule
  ],
  providers: [NbSidebarService, NbMenuService],
  bootstrap: [AppComponent]
})
export class AppModule { }
